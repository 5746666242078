<template>
  <section class="table-report-wrapper">
    <div class="table-report-head table-head-standard flex items-center relative">
      <p v-for="column in allowedColumns" :key="column" :class="columnsData[column].class">
        {{ $t(columnsData[column].translateText) }}
      </p>
    </div>
    <app-scrollbar width="100%" height="36rem" scroll="y" slide="y" class="table-standard mt-4">
      <div
        v-for="player in rowsData"
        :key="player.position"
        class="table-standard-row relative flex items-center"
        :class="{ my: player.id === getUserPosition }"
      >
        <div
          v-if="allowedColumns.includes(REPORT_TABLE_COLUMNS.POSITION)"
          class="table-report-pos flexing font-bold italic text-36"
          :class="{
            'text-texts-standard-dark': ($isWsm && player.position <= 3) || $isSsm,
            'text-texts-standard-default': $isWsm && player.position > 3,
            gold: player.position === 1,
            silver: player.position === 2,
            bronze: player.position === 3,
          }"
        >
          {{ player.position }}
        </div>
        <app-user
          v-if="allowedColumns.includes(REPORT_TABLE_COLUMNS.NAME)"
          :id="player.id || ''"
          class="table-report-player"
          :country="player.country.three_letter_code"
          :name="player.name"
          :is-bot="player.bot"
          :badges-data="player.event_badges ?? null"
        />
        <div
          v-if="allowedColumns.includes(REPORT_TABLE_COLUMNS.RANKING_POINTS)"
          key=""
          class="table-report-points flexing"
        >
          <span
            class="triangle"
            :class="player.rankingPoints < 0 ? 'triangle-down red' : 'triangle-up green'"
          />
          <span class="table-report-points-value text-texts-standard-default text-32 italic">{{
            player.rankingPoints
          }}</span>
          <app-main-icon icon-name="ranking_points" :icon-size="32" />
        </div>
        <div v-if="allowedColumns.includes(REPORT_TABLE_COLUMNS.POWER)" class="table-report-attr">
          <p class="text-texts-standard-default text-32">
            {{ player.power ?? 0 }}
          </p>
        </div>
        <div
          v-if="allowedColumns.includes(REPORT_TABLE_COLUMNS.RESULT)"
          class="table-report-final italic text-texts-standard-important flexing text-34"
          :class="{
            'font-bold': $isWsm,
            'font-semibold': $isSsm,
          }"
        >
          <arena-report-result-icon
            :discipline-id="disciplineId"
            :report-data="player.stats.reportData"
          />
          {{
            player.stats.fail_status
              ? $t(`arena.${player.stats.fail_status}`)
              : formatResult(player.stats.result, disciplineId)
          }}
        </div>
      </div>
    </app-scrollbar>
  </section>
  <component-loading :is-loading="!rowsData.length" />
</template>

<script lang="ts">
import ArenaReportResultIcon from '@/components/Arena/ArenaReportResultIcon.vue'
import AppUser from '@/components/GlobalComponents/AppUser.vue'
import { DISCIPLINE_ID, REPORT_TABLE_COLUMNS, pathImages } from '@/globalVariables'
import { formatResult } from '@/helpers'
import { useUserStore } from '@/store/pinia/userStore'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'TableReport',
  components: {
    ArenaReportResultIcon,
    AppUser,
  },
  props: {
    rowsData: {
      type: Array,
      default: () => [],
    },
    disciplineId: {
      type: Number,
      required: true,
    },
    allowedColumns: {
      type: Array,
      default: () => [...Object.values(REPORT_TABLE_COLUMNS)],
      validator(value) {
        return Object.values(REPORT_TABLE_COLUMNS).includes(...value)
      },
    },
  },
  data() {
    return {
      pathImages,
      REPORT_TABLE_COLUMNS,
      columnsData: {
        [REPORT_TABLE_COLUMNS.POSITION]: {
          translateText: 'rankingsGroup.pos',
          class: 'table-report-head-pos',
        },
        [REPORT_TABLE_COLUMNS.NAME]: {
          translateText: 'common.name',
          class: 'table-report-head-player',
        },
        [REPORT_TABLE_COLUMNS.RANKING_POINTS]: {
          translateText: 'rankingsGroup.rankingPoints',
          class: 'table-report-head-points',
        },
        [REPORT_TABLE_COLUMNS.POWER]: {
          translateText: 'career.totalPower',
          class: 'table-report-head-attr',
        },
        [REPORT_TABLE_COLUMNS.RESULT]: {
          translateText: '',
          class: 'table-report-head-final',
        },
      },
    }
  },
  computed: {
    ...mapState(useUserStore, {
      user: 'getUserId',
    }),
    getUserPosition() {
      return this.rowsData.find((player) => player.id === this.user)?.id
    },
  },
  created() {
    const nonTimeColumns = [DISCIPLINE_ID.skiJump, DISCIPLINE_ID.archery, DISCIPLINE_ID.trap]
    this.columnsData[REPORT_TABLE_COLUMNS.RESULT].translateText = nonTimeColumns.includes(
      this.disciplineId,
    )
      ? 'rankingsGroup.points'
      : this.disciplineId === DISCIPLINE_ID.javelinThrow
        ? 'arena.performance'
        : 'common.time'
  },
  methods: {
    formatResult,
  },
})
</script>

<style lang="scss" scoped>
.table-report {
  &-wrapper {
    width: 117.5rem;
    margin: 1.25rem auto 0;

    .table-standard-row {
      width: 99.5%;
      margin-bottom: 0.563rem;
      height: 3.938rem;
      overflow: hidden;

      &:nth-last-child(2) {
        margin-bottom: 0;
      }
    }
  }

  &-head {
    background: #09172a;

    &-pos {
      width: 5.938rem;
    }

    &-player {
      width: 44rem;
      position: relative;
      left: 10rem;
      text-align: left;
    }

    &-points {
      width: 18rem;
    }

    &-attr {
      width: 35rem;
    }

    &-final {
      width: 14.063rem;
      position: absolute;
      right: 0;

      .position {
        margin: 2rem;
      }
    }
  }

  &-pos {
    width: 5rem;
    height: 100%;
    position: relative;
    clip-path: polygon(0% 0%, 100% 0%, 87.5% 100%, 0% 100%);
    padding-right: 0.375rem;

    @if $isWsm {
      background-color: rgba(20, 56, 88, 0.5);
    }

    @if $isSsm {
      background-color: #ffffff;
    }

    &.gold {
      @if $isWsm {
        background-image: linear-gradient(to top, #e9d27a, #f6c717);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #daab36, #ffdf91);
      }
    }

    &.silver {
      @if $isWsm {
        background-image: linear-gradient(to top, #8e8e8e, #dbdbdb);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #c3c3c3, #f5f0f0);
      }
    }

    &.bronze {
      @if $isWsm {
        background-image: linear-gradient(to top, #b75a32, #ef9f73);
      }

      @if $isSsm {
        background-image: linear-gradient(to right, #f58d57, #ffb28a);
      }
    }
  }

  &-player {
    width: 44rem;
    justify-content: flex-start;
    padding-left: 2rem;

    &-country {
      width: 3.75rem;
      font-size: 1.4375rem;
      font-style: italic;
      font-weight: bold;
      margin: 0;
    }

    &-flag {
      background-repeat: no-repeat;
      background-size: contain;
      width: 3.15rem;
      min-width: 3.15rem;
      height: 2.2rem;
      margin: 0 1rem 0 0.5rem;
    }
  }

  &-points {
    width: 18rem;

    &-value {
      margin: 0 0.5rem 0 0.75rem;
    }
  }

  &-attr {
    width: 35rem;
  }

  &-final {
    width: 14.063rem;
    height: 101%;
    position: absolute;
    right: 0;

    @if $isWsm {
      background-color: rgba(20, 56, 88, 0.5);
    }

    @if $isSsm {
      background-color: rgb(34, 40, 61, 0.5);

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -1.5rem;
        width: 0.5rem;
        height: 100%;
        background-color: rgb(34, 40, 61, 0.5);
        transform: skewX(-12deg);
      }
    }

    &::after {
      content: '';
      height: 100%;
      width: 1rem;
      position: absolute;
      left: -0.9627rem;
      clip-path: polygon(80% 0%, 100% 0%, 100% 100%, 0% 100%);

      @if $isWsm {
        background-color: rgba(20, 56, 88, 0.5);
      }

      @if $isSsm {
        background-color: rgb(34, 40, 61, 0.5);
      }
    }
  }
}

@if $isSsm {
  .my .table-report-final {
    background-color: rgba(4, 26, 51, 0.5);

    &::before,
    &::after {
      background-color: rgba(4, 26, 51, 0.5);
    }
  }
}
</style>
