import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  clubBallonAnimations,
  clubHelicopterRoundAnimations,
  cableCarToDownAnimations,
  cableCarToUpAnimations,
  clubSkierRightAnimations,
  carBottomLeftToRightAnimations,
  carBottomRightToLeftAnimations,
} from './animations'
import {
  clubHelicopterRoundCheckpoints,
  cableCarToDownCheckpoints,
  cableCarToUpCheckpoints,
  clubSkierRightCheckpoints,
  carBottomLeftToRightCheckpoints,
  carBottomRightToLeftCheckpoints,
} from './checkPointsData'
import {
  clubBallonRightPath,
  clubBallonTopMiddlePath,
  clubHelicopterRoundPath,
  cableCarToDownPath,
  cableCarToUpPath,
  clubSkierRightPath,
  carBottomLeftToRightPath,
  carBottomRightToLeftPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'clubBallonTopMiddle',
    textureKey: 'animations',
    animationsData: clubBallonAnimations,
    pathsData: clubBallonTopMiddlePath,
    variations: ['v01', 'v02'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 100000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonTopMiddle1',
        startDelay: 0,
        yoyoDelay: 20000,
      },
    ],
  },
  {
    animationName: 'clubBallonRight',
    textureKey: 'animations',
    pathsData: clubBallonRightPath,
    variations: ['v01'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'clubBalloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 20000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubBallonRight1',
        startDelay: 0,
        yoyoDelay: 10000,
      },
    ],
  },
  {
    animationName: 'clubHelicopterRound',
    textureKey: 'animations',
    pathsData: clubHelicopterRoundPath,
    animationsData: clubHelicopterRoundAnimations,
    checkpointsData: clubHelicopterRoundCheckpoints,
    variations: ['v01'],
    framePrefix: 'WSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 90000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubHelicopterRound1',
        startDelay: 1000,
      },
    ],
  },
  {
    animationName: 'cableCarToDown',
    textureKey: 'animations',
    pathsData: cableCarToDownPath,
    checkpointsData: cableCarToDownCheckpoints,
    animationsData: cableCarToDownAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '28',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 50000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarToDownAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarToDownAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarToDownAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'cableCarToUp',
    textureKey: 'animations',
    pathsData: cableCarToUpPath,
    checkpointsData: cableCarToUpCheckpoints,
    animationsData: cableCarToUpAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 45000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarToUpAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarToUpAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarToUpAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'clubSkierRight',
    textureKey: 'animations',
    pathsData: clubSkierRightPath,
    checkpointsData: clubSkierRightCheckpoints,
    animationsData: clubSkierRightAnimations,
    variations: ['v01', 'v04'],
    framePrefix: 'WSM_skier_',
    framePostfix: '_11f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '10',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 30000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'clubSkierRight1',
        startDelay: 0,
      },
      {
        name: 'clubSkierRight2',
        startDelay: 5500,
      },
      {
        name: 'clubSkierRight3',
        startDelay: 10000,
      },
      {
        name: 'clubSkierRight4',
        startDelay: 18800,
      },
    ],
  },
  {
    animationName: 'carBottomLeftToRight',
    textureKey: 'animations',
    pathsData: carBottomLeftToRightPath,
    checkpointsData: carBottomLeftToRightCheckpoints,
    animationsData: carBottomLeftToRightAnimations,
    variations: ['v01', 'v02', 'v03'],
    framePrefix: 'WSM_cars_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '14',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carBottomLeftToRight1',
        startDelay: 0,
      },
      {
        name: 'carBottomLeftToRight2',
        startDelay: 3000,
      },
      {
        name: 'carBottomLeftToRight3',
        startDelay: 6000,
      },
      {
        name: 'carBottomLeftToRight4',
        startDelay: 9000,
      },
      {
        name: 'carBottomLeftToRight5',
        startDelay: 15000,
      },
    ],
  },
  {
    animationName: 'carBottomRightToLeft',
    textureKey: 'animations',
    pathsData: carBottomRightToLeftPath,
    checkpointsData: carBottomRightToLeftCheckpoints,
    animationsData: carBottomRightToLeftAnimations,
    variations: ['v04', 'v05', 'v06'],
    framePrefix: 'WSM_cars_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '11',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'carBottomRightToLeft1',
        startDelay: 0,
      },
      {
        name: 'carBottomRightToLeft2',
        startDelay: 3000,
      },
      {
        name: 'carBottomRightToLeft3',
        startDelay: 8000,
      },
      {
        name: 'carBottomRightToLeft4',
        startDelay: 11000,
      },
      {
        name: 'carBottomRightToLeft5',
        startDelay: 15000,
      },
    ],
  },
]
