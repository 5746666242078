import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarRightToDownCheckpoints: CheckpointsObjectInterface = {
  key: 'cableCarRightToDownCheckpoints',
  data: [
    {
      x: 2743,
      y: 275,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarRightToDownAnimations1',
        },
      },
    },
  ],
}
