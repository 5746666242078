<template>
  <div class="seasons-rewards-item flexing">
    <div class="seasons-rewards-item-left">
      <div
        class="seasons-rewards-item-left-image"
        :style="{
          background: renderRow(seasonReward.type as RewardType).image,
          backgroundSize: 'contain',
        }"
      />
    </div>

    <div class="text-white seasons-rewards-item-right">
      <div class="flex flex-col h-full">
        <div class="flex justify-between items-center seasons-rewards-item-right-header">
          <p class="text-texts-standard-dark text-32 uppercase font-bold">
            {{ $t(renderRow(seasonReward.type as RewardType).name) }}
          </p>
          <p class="text-texts-standard-dark text-28 font-bold">
            {{
              $replacePlaceholder(
                $t(renderRow(seasonReward.type as RewardType).text),
                '%s',
                TOP_PLAYERS,
              )
            }}:
            <span
              class="text-36"
              :class="{
                'text-texts-standard-important': $isWsm,
                'text-texts-standard-default': $isSsm,
              }"
            >
              {{ seasonReward.topValue }}
            </span>
          </p>
        </div>
        <div class="flex justify-between items-center seasons-rewards-item-right-content">
          <div v-if="seasonReward.rewards.length" class="flex justify-between items-center">
            <reward-box
              v-for="(reward, i) in seasonReward.rewards"
              :key="i"
              class="flex justify-start"
              :reward-number="reward.amount"
              :reward-icon-name="getRewardName(reward)"
              :reward-icon-rarity="reward.rarity"
              :reward-icon-size="56"
            />
          </div>
          <div v-else class="flexing seasons-rewards-item-right-content-no-rewards">
            <p class="text-28">
              {{ $t('seasons.noReward') }}
            </p>
          </div>
          <app-button
            btn-type="primary"
            :btn-text="$t('seasons.detail')"
            btn-size="xsm"
            @click="renderRow(seasonReward.type as RewardType).openPopup"
          />
        </div>
      </div>
    </div>
  </div>

  <seasons-arena-popup
    v-if="showSeasonsArenaPopup"
    :show-popup="showSeasonsArenaPopup"
    @close="showSeasonsArenaPopup = false"
  />

  <seasons-seasonal-attributes-popup
    v-if="showSeasonalAttributesPopup"
    :show-popup="showSeasonalAttributesPopup"
    @close="showSeasonalAttributesPopup = false"
  />

  <seasons-world-ranking-popup
    v-if="showWorldRankingPopup"
    :show-popup="showWorldRankingPopup"
    @close="showWorldRankingPopup = false"
  />
</template>

<script lang="ts">
import SeasonsArenaPopup from '@/components/Seasons/SeasonsArenaPopup.vue'
import SeasonsSeasonalAttributesPopup from '@/components/Seasons/SeasonsSeasonalAttributesPopup.vue'
import SeasonsWorldRankingPopup from '@/components/Seasons/SeasonsWorldRankingPopup.vue'
import {
  ARENA,
  CATEGORY_BENEFIT,
  SEASONAL_ATTRIBUTES,
  TOP_PLAYERS,
  WORLD_RANKING,
  pathImages,
} from '@/globalVariables'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { SeasonRewardType, SeasonReward } from '@/interfaces/Seasons'
import type { Nullable } from '@/interfaces/utils'

enum RewardType {
  Arena = ARENA,
  SeasonalAttributes = SEASONAL_ATTRIBUTES,
  WorldRanking = WORLD_RANKING,
}
interface RewardRow {
  name: string
  image: string
  text: string
  openPopup: () => void
}
interface ComponentData {
  CATEGORY_BENEFIT: typeof CATEGORY_BENEFIT
  TOP_PLAYERS: typeof TOP_PLAYERS
  pathImages: typeof pathImages
  showSeasonsArenaPopup: boolean
  showSeasonalAttributesPopup: boolean
  showWorldRankingPopup: boolean
}

export default defineComponent({
  name: 'SeasonsRewardsItem',
  components: {
    SeasonsArenaPopup,
    SeasonsSeasonalAttributesPopup,
    SeasonsWorldRankingPopup,
  },
  props: {
    seasonReward: {
      type: Object as PropType<Nullable<SeasonRewardType>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      CATEGORY_BENEFIT,
      TOP_PLAYERS,
      pathImages,
      showSeasonsArenaPopup: false,
      showSeasonalAttributesPopup: false,
      showWorldRankingPopup: false,
    }
  },
  methods: {
    renderRow(type: RewardType): RewardRow {
      if (type === RewardType.Arena) {
        return {
          name: 'map.arenaName',
          image:
            'url(' +
            pathImages +
            `benefits/benefit_building_arena${this.$isWsm ? '_v2' : ''}.avif ) center no-repeat`,
          text: 'seasons.maxLevelReached',
          openPopup: () => this.openSeasonsArenaPopup(),
        }
      }

      if (type === RewardType.SeasonalAttributes) {
        return {
          name: 'seasons.seasonalAttributes',
          image: 'url(' + pathImages + 'seasons/season-attribute-bg.avif ) center no-repeat',
          text: 'seasons.trainedAttributes',
          openPopup: () => this.openSeasonalAttributesPopup(),
        }
      }

      if (type === RewardType.WorldRanking) {
        return {
          name: 'seasons.worldRanking',
          image: 'url(' + pathImages + 'seasons/WORLD-RANKING-ICO.avif ) center no-repeat',
          text: 'seasons.topInDisciplines',
          openPopup: () => this.openWorldRankingPopup(),
        }
      }

      return {} as RewardRow
    },
    openSeasonsArenaPopup(): void {
      this.showSeasonsArenaPopup = true
    },
    openSeasonalAttributesPopup(): void {
      this.showSeasonalAttributesPopup = true
    },
    openWorldRankingPopup(): void {
      this.showWorldRankingPopup = true
    },
    getRewardName(reward: SeasonReward): SeasonReward['name'] {
      if (
        reward.type === 'attribute' ||
        reward.type === 'training_points' ||
        reward.type === 'cash'
      )
        return `${reward.name}_${reward.rarity}`

      return reward.name
    },
  },
})
</script>

<style lang="scss" scoped>
.seasons-rewards-item {
  height: 14rem;
  border: 0.063rem solid #6c9ec4;
  background-image: url($path-images + 'seasons/season-reward-item-bg.avif');
  background-size: 100% 100%;
  margin-bottom: 1.95rem;

  &-left {
    width: 21%;

    &-image {
      width: 20rem;
      height: 10rem;
    }
  }

  &-right {
    width: 79%;
    height: 100%;

    &-header {
      height: 5rem;
      padding-left: 4rem;
      padding-right: 2rem;
      background-image: url($path-images + 'seasons/BG_HEADER_SKEW.avif');
      background-size: 100% 100%;
    }

    &-content {
      height: 100%;
      padding-left: 3rem;
      padding-right: 2rem;

      &-no-rewards {
        width: 65%;
      }
    }
  }
}
</style>
