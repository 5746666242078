import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const skierRightPath: PathObjectInterface = {
  mainPath: { x: 1920, y: 755 },
  spline: [
    1920, 755, 2042, 825, 2133, 851, 2311, 851, 2397, 877, 2455, 909, 2491, 954, 2517, 1012, 2527,
    1089, 2524, 1122, 2568, 1144, 2604, 1173, 2616, 1233, 2644, 1285, 2692, 1326, 2745, 1362, 2788,
    1432, 2798, 1501, 2815, 1576, 2832, 1626, 2860, 1691, 2877, 1770, 2935, 1902, 2988, 1977, 3064,
    2063,
  ],
}
