import { boardGamesEventStateEndpoint as BOARD_GAMES_EVENT_STATE_ENDPOINT } from '@/globalVariables'
import { isFlutterApp } from '@/helpers'
import { isMobile } from '@/plugins'
import { useUserStore } from './userStore'
import { useEventInfoStore } from './events/eventInfoStore'
import type BoardGamesEventStateApiResponse from '@/interfaces/responses/BoardGamesEventApiStateResponse'
import type { Nullable } from '@/interfaces/utils'
import { defineStore } from 'pinia'
import { ApiService } from '@/services/ApiService'
import { BoardGamesEventType } from '@/interfaces/responses/BoardGamesEventApiStateResponse'

interface BoardGamesEventState {
  config: BoardGamesEventStateApiResponse | null
  checkTokenInterval: ReturnType<typeof setInterval>
}

export const useBoardGamesEventStore = defineStore('boardGamesEvent', {
  state: (): BoardGamesEventState => ({
    config: null,
    checkTokenInterval: null,
  }),
  getters: {
    eventTeaserStartsIn(): Nullable<number> {
      if (!this.config) return null
      return new Date(this.config?.teaser_start_time).getTime() - Date.now()
    },
    eventTeaserEndsIn(): Nullable<number> {
      return this.eventStarsIn
    },
    isEventTeaserActive(): Nullable<boolean> {
      if (!this.config) return null
      return this.eventTeaserStartsIn < 0 && this.eventTeaserEndsIn > 0
    },
    eventStarsIn(): Nullable<number> {
      if (!this.config) return null
      return new Date(this.config?.start_time).getTime() - Date.now()
    },
    eventEndsIn(): Nullable<number> {
      if (!this.config) return null
      return new Date(this.config?.end_time).getTime() - Date.now()
    },
    isEventActive(): Nullable<boolean> {
      if (!this.config) return null
      return this.eventStarsIn < 0 && this.eventEndsIn > 0
    },
    eventTimer(): Nullable<number> {
      if (!this.config) return null
      return [this.eventTeaserEndsIn, this.eventEndsIn].find(
        (seconds: number): boolean => !isNaN(seconds) && seconds > 0,
      )
    },
    isEventLocked(): Nullable<boolean> {
      return this.config?.event_locked ?? null
    },
    eventSearchParams(): Record<string, string> {
      const userStore = useUserStore()
      return {
        data: this.config?.user_data ?? '',
        mobile: isMobile() ? '1' : '0',
        flutter: isFlutterApp() ? '1' : '0',
        sounds: userStore.isSoundEnabled ? '1' : '0',
        language: userStore.getLanguage ?? '',
      }
    },
    eventQueryString(): string {
      return new URLSearchParams(this.eventSearchParams).toString()
    },
    eventType(): BoardGamesEventStateApiResponse['event_type'] {
      if (!this.config) return null
      return this.config?.event_type ?? BoardGamesEventType.BoardGames
    },
    isIceAndFireEvent(): boolean {
      return this.eventType === BoardGamesEventType.IceAndFire
    },
  },
  actions: {
    async loadEvent(force: boolean = false): Promise<void> {
      if (this?.config !== null && !force) return

      try {
        this.config = await ApiService.get<BoardGamesEventStateApiResponse>(
          BOARD_GAMES_EVENT_STATE_ENDPOINT,
          { force },
        )
      } catch (error: unknown) {
        console.error(error)
      }
    },
    checkUserAuthorization(): void {
      // ked je spusteny BG, v intervaloch sa robi dopyt na BE pre kontrolu, ci je pouzivatel este autorizovany.. po konzultaci s BE bol pouzity EP na load eventu

      if (this?.checkTokenInterval) return

      this.checkTokenInterval = setInterval(() => {
        useEventInfoStore().loadEvent(true)
      }, 15000)
    },

    clearCheckTokenInterval(): void {
      if (!this?.checkTokenInterval) return

      clearInterval(this.checkTokenInterval)
      this.checkTokenInterval = null
    },
  },
})
