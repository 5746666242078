import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const skierMiddleCheckpoints: CheckpointsObjectInterface = {
  key: 'skierMiddleCheckpoints',
  data: [
    {
      x: 1800,
      y: 1041,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierMiddleAnimations1',
        },
      },
    },
    {
      x: 2109,
      y: 1374,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierMiddleAnimations2',
        },
      },
    },
    {
      x: 2176,
      y: 1549,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierMiddleAnimations3',
        },
      },
    },
  ],
}
