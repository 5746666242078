<template>
  <div class="t-buildings app-page-wrapper absolute" data-cy="t-buildings">
    <training-menu />
    <section class="w-full h-full absolute safe-area">
      <div class="t-buildings-main m-auto">
        <header-info :font-size="30">{{ $t('trainingHall.improveTrainingHall') }}</header-info>
        <app-scrollbar
          v-if="isTrainingBuildingDataLoaded"
          class="th-building-scroll flex items-center m-auto"
          width="100%"
          height="auto"
          scroll="x"
          slide="x"
          :arrows="true"
          :scroll-arrows-safe-zone-indented="true"
        >
          <div
            v-for="(building, index) in buildings"
            :key="index"
            class="t-buildings-main-box__wrapper m-2 relative"
            :class="{
              't-buildings-main-box__wrapper--last': index == buildings.length - 1,
            }"
          >
            <div
              v-if="building.level > buildingsActual.level + 1"
              class="icon-lock-sm absolute top-3.5 right-3.5 z-2"
            ></div>

            <div
              :id="'t-building-box-' + building.level"
              class="t-buildings-main-box"
              :class="{
                'gold-trim': building.level == buildingsActual.level,
              }"
            >
              <!--- LOCK a UPGRADE BOXU ---->
              <div
                v-show="building.level !== buildingsActual.level"
                class="t-buildings-main-box-lock absolute"
                :class="[building.level < buildingsActual.level ? 'light' : '']"
              >
                <div
                  v-if="
                    building.level > buildingsActual.level &&
                    building.level > buildingsActual.level + 1
                  "
                  class="icon-lock absolute top-2 right-2"
                />
                <div
                  v-if="
                    buildingsActual.level + 1 && buildingsActual?.children?.level == building.level
                  "
                  class="upgrade-box absolute"
                >
                  <div class="upgrade-box-header uppercase">
                    <shrink-text
                      :text="$t('trainingHall.levelUpTrainingHall')"
                      container-class-list="flexing h-full"
                      :font-size="36"
                      :max-line-limit="2"
                    />
                  </div>
                  <div class="upgrade-box-center m-auto">
                    <div class="upgrade-box-center-up flexing">
                      <div class="text-up">
                        <shrink-text :text="$t('trainingHall.trainingHallLevel')" :font-size="32" />
                      </div>
                      <div
                        class="level-up text-texts-standard-important"
                        :class="{
                          'font-bold': $isSsm,
                        }"
                      >
                        {{ building.level - 1 }}
                      </div>
                      <app-icon
                        v-show="buildingsActual.level == building.level - 1"
                        icon-name="done-sm"
                      />
                    </div>
                    <div class="upgrade-box-center-down flexing">
                      <div class="text-down">
                        <shrink-text :text="$t('trainingHall.userLevel')" :font-size="32" />
                      </div>
                      <div
                        class="level text-texts-standard-important"
                        :class="{
                          'font-bold': $isSsm,
                        }"
                      >
                        {{ building.parameters.min_user_level }}
                      </div>
                      <app-icon
                        v-show="building.parameters.min_user_level <= Number(userLevel)"
                        icon-name="done-sm"
                      />
                    </div>
                  </div>
                  <app-multi-button
                    btn-id="training-hall-upgrade"
                    btn-type="confirm"
                    :btn-text="$t('button.upgrade')"
                    btn-size="md"
                    class="inline-flex align-top mx-4 mt-6"
                    :class="{
                      'lockUpgrade ':
                        userMoney.value < building.parameters.price ||
                        Number(userLevel) < building.parameters.min_user_level,
                    }"
                    @click="upgradeLevel"
                  >
                    <template #rightPart>
                      <span class="pt-1">
                        {{
                          $filters.$formatNumber(
                            $filters.$abbrNumber(Number(building.parameters.price)),
                          )
                        }}
                      </span>
                      <app-main-icon :icon-size="56" :icon-name="'money'" />
                    </template>
                  </app-multi-button>
                </div>
                <div v-show="building.level > buildingsActual.level + 1" class="lock-box">
                  <div class="lock-box-header">
                    <shrink-text
                      :text="$t('trainingHall.toUnlock')"
                      container-class-list="flexing h-full"
                      text-class-list="text-texts-standard-important"
                      :font-size="36"
                      :max-line-limit="2"
                    />
                  </div>
                  <div class="lock-box-center m-auto">
                    <div class="lock-box-center-up flex items-cente justify-between">
                      <div class="text-up">
                        <shrink-text :text="$t('trainingHall.trainingHallLevel')" :font-size="32" />
                      </div>
                      <div
                        class="level-up text-texts-standard-important"
                        :class="{
                          'font-bold': $isSsm,
                        }"
                      >
                        {{ building.level - 1 }}
                      </div>
                    </div>
                    <div class="lock-box-center-down flex items-cente justify-between">
                      <div class="text-down">
                        <shrink-text :text="$t('trainingHall.userLevel')" :font-size="32" />
                      </div>
                      <div
                        class="level text-texts-standard-important"
                        :class="{
                          'font-bold': $isSsm,
                        }"
                      >
                        {{ building.parameters.min_user_level }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!----   KONIEC LOCK,UPGRADE BOXU    ------->
              <div class="t-buildings-main-box-section">
                <div
                  class="t-buildings-main-box-section-level uppercase flexing italic relative"
                  :class="{
                    'gold-header': building.level == buildingsActual.level,
                    'unlocked-header': unlockLevel(index),
                    'text-texts-standard-name': $isWsm,
                    'text-texts-standard-additional': $isSsm,
                  }"
                  :style="{
                    'z-index': buildingsActual.level < building.level ? '2' : '0',
                  }"
                >
                  {{
                    building.level == buildingsActual.level
                      ? $t('trainingHall.currentLevel')
                      : unlockLevel(index)
                        ? $t('common.unlocked')
                        : buildingsActual.level > building.level
                          ? $t('common.level') + ' ' + building.level
                          : $t('common.locked')
                  }}
                </div>
                <div
                  class="t-buildings-main-box-section-badge relative m-auto text-texts-standard-important flexing"
                >
                  {{ index + 1 }}
                </div>
                <div
                  class="t-buildings-main-box-section-hall"
                  :style="{
                    background:
                      'url(' +
                      pathImages +
                      hall_big +
                      getBuildingImageByLevel(building.level) +
                      '.avif )center no-repeat',

                    backgroundSize: 'contain',
                  }"
                />
                <div class="t-buildings-main-box-section-current uppercase relative flexing">
                  {{ $t('trainingHall.limits') }}
                </div>
              </div>
              <div class="t-buildings-main-box-footer flex justify-center">
                <div class="flexing">
                  <div
                    v-tippy="{
                      content: $t('trainingHall.newLimitOfTrainingPoints'),
                      placement: 'right',
                      theme: 'WSM',
                    }"
                    class="up flexing"
                  >
                    {{ $filters.$formatNumber(building.parameters.max_tp_limit) }}
                    <app-main-icon icon-name="universal_training_points" :icon-size="48" />
                  </div>
                </div>
                <div class="flexing">
                  <div
                    v-tippy="{
                      content: $t('trainingHall.newLimitOfAttributes'),
                      placement: 'right',
                      theme: 'WSM',
                    }"
                    class="down flexing"
                  >
                    {{ building.parameters.max_attribute }}
                    <app-icon icon-name="attribute-limit" :icon-size="48" class="ml-2" />
                  </div>
                  <div
                    v-if="building.parameters.max_seasonal_attribute"
                    v-tippy="{
                      content: $t('trainingHall.newLimitOfSeasonalAttributes'),
                      placement: 'right',
                      theme: 'WSM',
                    }"
                    class="down flex justify-center items-center"
                  >
                    {{ building.parameters.max_seasonal_attribute }}
                    <app-icon
                      :icon-name="'seasonal-attribute-limit'"
                      :icon-size="48"
                      class="ml-2"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-scrollbar>

        <component-loading
          width="100%"
          :height="'45.4375rem'"
          :is-loading="!isTrainingBuildingDataLoaded"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import HeaderInfo from '@/components/Header/HeaderInfo.vue'
import {
  pathImages,
  trainingActualGradesEndpoint,
  trainingGradesEndpoint,
  trainingUpgradeLevel,
} from '@/globalVariables'
import { convertNameValue, getBuildingImageByLevel } from '@/helpers'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useUserStore } from '@/store/pinia/userStore'
import TrainingMenu from '@/views/training/TrainingMenu.vue'
import { mapState } from 'pinia'
import { defineComponent } from 'vue'
import ShrinkText from '@/components/ShrinkText.vue'

import type { TrainingBuildingApiResponse } from '@/interfaces/responses/training/TrainingBuildingApiResponse'

interface ComponentData {
  hall_big: string
  pathImages: typeof pathImages
  buildings: TrainingBuildingApiResponse[]
  buildingsActual: TrainingBuildingApiResponse
  isTrainingBuildingDataLoaded: boolean
  textsForTranslate: string[]
}

export default defineComponent({
  name: 'TrainingBuildings',
  components: {
    TrainingMenu,
    HeaderInfo,
    ShrinkText,
  },

  data(): ComponentData {
    return {
      hall_big: `training/building/BUILDING_TRAINING_HALL_LARGE${this.$isWsm ? '_V2' : ''}_LVL`,
      pathImages,
      buildings: [],
      buildingsActual: {} as TrainingBuildingApiResponse,
      isTrainingBuildingDataLoaded: false,
      textsForTranslate: [
        'trainingHall.improveTrainingHall',
        'trainingHall.trainingHallUpgraded',
        'common.congratulations',
        'trainingHall.newLimitOfTrainingPoints',
        'trainingHall.newLimitOfAttributes',
        'trainingHall.newLimitOfSeasonalAttributes',
        'trainingHall.levelUpTrainingHall',
        'trainingHall.header',
        'common.building',
        'button.upgrade',
        'trainingHall.tutorials',
        'trainingHall.training',
        'trainingHall.aboutTrainingHeaderInfo',
        'trainingHall.aboutTrainingIncreaseAttributesInfo',
        'trainingHall.aboutTrainingTpInfo',
        'trainingHall.aboutTrainingUniversalTpInfo',
      ],
    }
  },
  computed: {
    ...mapState(useUserStore, {
      userLevel: 'getLevel',
    }),
    ...mapState(useResponseTaskStore, {
      userMoney: 'getMoney',
    }),
    isContentLoading(): boolean {
      return this.buildings.length === 0
    },
  },
  async created(): Promise<void> {
    await Promise.all([this.getBuildingsData(), this.getActualBuilding()]).then((): void => {
      this.isTrainingBuildingDataLoaded = true
    })
  },
  mounted() {
    setTimeout((): void => {
      this.scrollToActual()
    }, 1000)
  },
  methods: {
    getBuildingImageByLevel,
    async getBuildingsData(): Promise<void> {
      const response = await this.$axios.get<{}, TrainingBuildingApiResponse[]>(
        trainingGradesEndpoint,
      )

      this.buildings = response.reduce(
        (
          finalArray: TrainingBuildingApiResponse[],
          building: TrainingBuildingApiResponse,
        ): TrainingBuildingApiResponse[] => {
          if (building.level > 0) {
            building.parameters = convertNameValue(building.parameters)
            finalArray.push(building)
          }
          return finalArray
        },
        [],
      )
    },
    async upgradeLevel(): Promise<void> {
      this.buildingsActual = await this.$axios.put<{}, TrainingBuildingApiResponse>(
        trainingUpgradeLevel,
      )
    },
    async getActualBuilding(): Promise<void> {
      this.buildingsActual = await this.$axios.get<{}, TrainingBuildingApiResponse>(
        trainingActualGradesEndpoint,
      )
    },
    unlockLevel(level: number): boolean {
      if (
        this.buildings[level].parameters.min_user_level <= Number(this.userLevel) &&
        level === this.buildingsActual.level
      ) {
        return true
      }
    },
    scrollToActual(): void {
      const container = this.$el.querySelector('.th-building-scroll')
      if (container) {
        this.$el
          .querySelector('#t-building-box-' + this.buildingsActual.level)
          .scrollIntoView({ behavior: 'smooth', inline: 'start' })
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/training/buildings.scss';

.component-loading-wrapper {
  width: 95%;

  .component-loading {
    border: 0.125rem solid #59a2db;
  }
}
</style>
