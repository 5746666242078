import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarLeftToUpCheckpoints: CheckpointsObjectInterface = {
  key: 'cableCarLeftToUpCheckpoints',
  data: [
    {
      x: 1365,
      y: 1801,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarLeftToUpAnimations1',
        },
      },
    },
    {
      x: 961,
      y: 1516,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarLeftToUpAnimations2',
        },
      },
    },
    {
      x: 571,
      y: 959,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarLeftToUpAnimations3',
        },
      },
    },
  ],
}
