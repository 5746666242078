import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarLeftToUpPath: PathObjectInterface = {
  mainPath: { x: 1435, y: 2100 },
  spline: [
    1425, 2077, 1415, 2015, 1394, 1933, 1379, 1859, 1377, 1825, 1365, 1801, 1338, 1780, 1280, 1746,
    1223, 1713, 1129, 1653, 1064, 1609, 1016, 1569, 961, 1516, 918, 1468, 856, 1389, 774, 1283, 736,
    1223, 673, 1137, 604, 1041, 577, 1005, 560, 981, 571, 959, 608, 930, 646, 899, 704, 846, 751,
    798, 825, 719, 897, 645, 967, 563, 1070, 448,
  ],
}
