import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const skierLeftPath: PathObjectInterface = {
  mainPath: { x: 1084, y: 551 },
  spline: [
    1084, 551, 1096, 532, 1044, 587, 1024, 616, 1005, 664, 986, 721, 962, 781, 948, 837, 933, 904,
    948, 966, 979, 1036, 1010, 1089, 1044, 1141, 1082, 1180, 1123, 1201, 1185, 1235, 1231, 1257,
    1363, 1261, 1425, 1256, 1492, 1256, 1552, 1246, 1612, 1234,
  ],
}
