import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const clubSkierRightPath: PathObjectInterface = {
  mainPath: { x: 2536, y: 383 },
  spline: [
    2536, 383, 2666, 431, 2779, 479, 2882, 529, 2956, 573, 3012, 597, 3067, 647, 3103, 714, 3120,
    805, 3112, 880, 3096, 981, 3062, 1077, 3009, 1141, 2947, 1197, 2872, 1237, 2769, 1276, 2623,
    1314, 2539, 1360, 2517, 1384, 2469, 1530, 2539, 1643, 2556, 1732, 2541, 1825, 2539, 1924, 2546,
    2010, 2556, 2080,
  ],
}
