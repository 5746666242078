import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const skierLeftCheckpoints: CheckpointsObjectInterface = {
  key: 'skierLeftCheckpoints',
  data: [
    {
      x: 1024,
      y: 616,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierLeftAnimations1',
        },
      },
    },
    {
      x: 948,
      y: 966,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierLeftAnimations2',
        },
      },
    },
    {
      x: 1123,
      y: 1201,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierLeftAnimations3',
        },
      },
    },
    {
      x: 1185,
      y: 1235,
      width: 23,
      height: 23,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierLeftAnimations4',
        },
      },
    },
  ],
}
