import type { AnimatedObjectWithPathsInterface } from '@/map-phaser-new/interfaces'
import { FRAMES_ANIMATION_TYPES } from '@/map-phaser-new/constants'
import {
  ballonAnimations,
  helicopterLeftToRightAnimations,
  cableCarRightToDownAnimations,
  cableCarRightToUpAnimations,
  cableCarLeftToDownAnimations,
  cableCarLeftToUpAnimations,
  skierSlalomAnimations,
  skierLeftAnimations,
  skierMiddleAnimations,
  skierRightAnimations,
} from './animations'
import {
  helicopterLeftToRightCheckpoints,
  cableCarRightToDownCheckpoints,
  cableCarRightToUpCheckpoints,
  cableCarLeftToDownCheckpoints,
  cableCarLeftToUpCheckpoints,
  skierSlalomCheckpoints,
  skierLeftCheckpoints,
  skierMiddleCheckpoints,
  skierRightCheckpoints,
} from './checkPointsData'
import {
  ballonTopLeftPath,
  ballonTopRightPath,
  helicopterLeftToRightPath,
  cableCarRightToDownPath,
  cableCarRightToUpPath,
  cableCarLeftToDownPath,
  cableCarLeftToUpPath,
  skierSlalomPath,
  skierLeftPath,
  skierMiddlePath,
  skierRightPath,
} from './pathsData'

export const animatedObjectsWithPathsConfig: AnimatedObjectWithPathsInterface[] = [
  {
    animationName: 'ballonTopLeft',
    textureKey: 'animations',
    pathsData: ballonTopLeftPath,
    animationsData: ballonAnimations,
    variations: ['v01', 'v02'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 130000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'ballonTopLeft1',
        startDelay: 0,
        yoyoDelay: 10000,
      },
    ],
  },
  {
    animationName: 'ballonTopRight',
    textureKey: 'animations',
    pathsData: ballonTopRightPath,
    variations: ['v01'],
    framePrefix: 'WSM_balloon_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    playAnimationWithoutCheckpoints: {
      animationKeyPostfix: 'balloonRotate',
    },
    turnOnRepeatListener: false,
    turnOnUpdateListener: false,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: true,
    tweenBuilderConfig: {
      duration: 130000,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'ballonTopRight1',
        startDelay: 0,
        yoyoDelay: 30000,
      },
    ],
  },
  {
    animationName: 'helicopterLeftToRight',
    textureKey: 'animations',
    pathsData: helicopterLeftToRightPath,
    checkpointsData: helicopterLeftToRightCheckpoints,
    animationsData: helicopterLeftToRightAnimations,
    variations: ['v01'],
    framePrefix: 'WSM_helicopter_',
    framePostfix: '_24f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '14',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 50000,
      from: 0,
      to: 1,
    },
    followersDepth: 11,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'helicopterLeftToRight1',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'cableCarRightToDown',
    textureKey: 'animations',
    pathsData: cableCarRightToDownPath,
    checkpointsData: cableCarRightToDownCheckpoints,
    animationsData: cableCarRightToDownAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '25',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 50000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarRightToDownAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarRightToDownAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarRightToDownAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'cableCarRightToUp',
    textureKey: 'animations',
    pathsData: cableCarRightToUpPath,
    checkpointsData: cableCarRightToUpCheckpoints,
    animationsData: cableCarRightToUpAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '03',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 45000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarRightToUpAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarRightToUpAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarRightToUpAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'cableCarLeftToDown',
    textureKey: 'animations',
    pathsData: cableCarLeftToDownPath,
    checkpointsData: cableCarLeftToDownCheckpoints,
    animationsData: cableCarLeftToDownAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '15',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 50000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarLeftToDownAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarLeftToDownAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarLeftToDownAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'cableCarLeftToUp',
    textureKey: 'animations',
    pathsData: cableCarLeftToUpPath,
    checkpointsData: cableCarLeftToUpCheckpoints,
    animationsData: cableCarLeftToUpAnimations,
    variations: ['v2'],
    framePrefix: 'WSM_cablecar_',
    framePostfix: '_36f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '02',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: false,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 45000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'cableCarLeftToUpAnimations1',
        startDelay: 0,
      },
      {
        name: 'cableCarLeftToUpAnimations2',
        startDelay: 15000,
      },
      {
        name: 'cableCarLeftToUpAnimations3',
        startDelay: 30000,
      },
    ],
  },
  {
    animationName: 'skierSlalom',
    textureKey: 'animations',
    pathsData: skierSlalomPath,
    checkpointsData: skierSlalomCheckpoints,
    animationsData: skierSlalomAnimations,
    variations: ['v03', 'v06'],
    framePrefix: 'WSM_skier_',
    framePostfix: '_11f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '10',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'skierSlalom',
        startDelay: 0,
      },
    ],
  },
  {
    animationName: 'skierLeft',
    textureKey: 'animations',
    pathsData: skierLeftPath,
    checkpointsData: skierLeftCheckpoints,
    animationsData: skierLeftAnimations,
    variations: ['v01', 'v04'],
    framePrefix: 'WSM_skier_',
    framePostfix: '_11f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '05',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'skierLeft1',
        startDelay: 1000,
      },
      {
        name: 'skierLeft2',
        startDelay: 5000,
      },
      {
        name: 'skierLeft3',
        startDelay: 10000,
      },
      {
        name: 'skierLeft4',
        startDelay: 15000,
      },
    ],
  },
  {
    animationName: 'skierMiddle',
    textureKey: 'animations',
    pathsData: skierMiddlePath,
    checkpointsData: skierMiddleCheckpoints,
    animationsData: skierMiddleAnimations,
    variations: ['v02', 'v05'],
    framePrefix: 'WSM_skier_',
    framePostfix: '_11f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '11',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 25000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'skierMiddle1',
        startDelay: 0,
      },
      {
        name: 'skierMiddle2',
        startDelay: 4000,
      },
      {
        name: 'skierMiddle3',
        startDelay: 8000,
      },
      {
        name: 'skierMiddle4',
        startDelay: 13000,
      },
    ],
  },
  {
    animationName: 'skierRight',
    textureKey: 'animations',
    pathsData: skierRightPath,
    checkpointsData: skierRightCheckpoints,
    animationsData: skierRightAnimations,
    variations: ['v01', 'v04'],
    framePrefix: 'WSM_skier_',
    framePostfix: '_11f_',
    startFrameData: {
      startFrameType: FRAMES_ANIMATION_TYPES.NormalFrameType,
      startFrameKey: '09',
    },
    turnOnRepeatListener: true,
    turnOnUpdateListener: true,
    turnOnYoyoListener: false,
    yoyoCalculateNewVariation: false,
    repeatCalculateNewVariation: true,
    yoyo: false,
    tweenBuilderConfig: {
      duration: 35000,
      from: 0,
      to: 1,
    },
    followersDepth: 2,
    startFollow: {
      repeat: -1,
      ease: 'linear',
      positionOnPath: true,
    },
    animatedInstances: [
      {
        name: 'skierRight1',
        startDelay: 0,
      },
      {
        name: 'skierRight2',
        startDelay: 5500,
      },
      {
        name: 'skierRight3',
        startDelay: 10000,
      },
      {
        name: 'skierRight4',
        startDelay: 18800,
      },
    ],
  },
]
