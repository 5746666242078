import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const skierMiddleAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'skierMiddleAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 10,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'skierMiddleAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 5,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'skierMiddleAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 4,
        zeroPad: 2,
      },
    },
  },
]
