<template>
  <div class="clubs-upgrade-popup">
    <main>
      <div class="building-wrapper relative">
        <h2 class="text-texts-standard-important text-30 uppercase">
          {{ $replacePlaceholder($t('minigame.level'), '%s', upgradeData.level.toString()) }}
        </h2>
        <div
          class="upgrade-building current-level"
          :style="{
            backgroundImage:
              'url(' +
              pathImages +
              'clubs/buildings/building-' +
              upgradeData.name.toLowerCase() +
              '-' +
              upgradeData.level +
              `${$isWsm ? '_v2' : ''}.avif)`,
          }"
        />
        <app-icon icon-name="done-md" class="upgrade-building-done" />
        <aside v-if="upgradeData.name === CLUBHOUSE">
          <div class="upgrade-building-income">
            <div class="upgrade-building-income-clubhouse flexing">
              <p class="text-texts-standard-default text-28 uppercase revert-skew">
                {{
                  $replacePlaceholder(
                    $t('club.membersCount'),
                    '%s',
                    upgradeData.currentValue.toString(),
                  )
                }}
              </p>
            </div>
          </div>
        </aside>
        <aside v-else>
          <div class="upgrade-building-income">
            <clubs-periodic-payment-box
              :reward-value="upgradeData.currentValue"
              reward-type="money"
              :reward-interval="upgradeData.time"
              :reward-size="28"
              :reward-icon-size="32"
            />
          </div>
        </aside>
      </div>
      <div class="building-arrow-wrapper">
        <div class="building-arrow-wrapper-icon" />
        <div class="building-arrow-wrapper-difference flexing">
          <p class="text-texts-standard-default text-28">+ {{ setDifference }}</p>

          <app-icon
            v-if="upgradeData.name === CLUBHOUSE"
            v-tippy="{
              theme: 'WSM',
              content: $t('club.clubPlayers'),
              placement: 'top',
            }"
            icon-name="members"
          />
          <app-main-icon v-else icon-name="money" :icon-size="32" />
        </div>
      </div>
      <div class="building-wrapper relative">
        <h2 class="text-texts-standard-important text-30 uppercase">
          {{ $replacePlaceholder($t('minigame.level'), '%s', getNextLevel.toString()) }}
        </h2>
        <div class="upgrade-building-background" />
        <div
          class="upgrade-building"
          :style="{
            backgroundImage:
              'url(' +
              pathImages +
              'clubs/buildings/building-' +
              upgradeData.name.toLowerCase() +
              '-' +
              upgradeData.level +
              `${$isWsm ? '_v2' : ''}.avif)`,
          }"
        />
        <aside v-if="upgradeData.name === CLUBHOUSE">
          <div class="upgrade-building-income">
            <div class="upgrade-building-income-clubhouse flexing">
              <p class="text-texts-standard-default text-28 uppercase revert-skew">
                {{
                  $replacePlaceholder(
                    $t('club.membersCount'),
                    '%s',
                    upgradeData.nextValue.toString(),
                  )
                }}
              </p>
            </div>
          </div>
        </aside>
        <aside v-else>
          <div class="upgrade-building-income">
            <clubs-periodic-payment-box
              :reward-value="upgradeData.nextValue"
              :reward-type="'money'"
              :reward-interval="upgradeData.time"
              :reward-size="28"
              :reward-icon-size="32"
            />
          </div>
        </aside>
      </div>
    </main>
    <footer class="flexing">
      <app-multi-button1
        btn-type="prestige"
        :btn-text="$t('button.upgrade')"
        :btn-count="upgradeData.price"
        @click="upgrade"
      >
      </app-multi-button1>
    </footer>
  </div>
</template>

<script lang="ts">
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import { CLUBHOUSE, pathImages } from '@/globalVariables'
import type { PropType } from 'vue'
import { defineComponent } from 'vue'

interface ComponentData {
  pathImages: typeof pathImages
  CLUBHOUSE: typeof CLUBHOUSE
}

export interface UpgradeDataInterface {
  level: number
  nextValue: number
  currentValue: number
  name: string
  time: number
  price: number
}

export default defineComponent({
  name: 'ClubsUpgradePopup',
  components: {
    ClubsPeriodicPaymentBox,
  },
  props: {
    upgradeData: {
      type: Object as PropType<UpgradeDataInterface>,
      required: true,
    },
  },
  emits: ['close', 'upgrade'],
  data(): ComponentData {
    return {
      pathImages,
      CLUBHOUSE,
    }
  },
  computed: {
    getNextLevel(): number {
      return Math.abs(this.upgradeData.level + 1)
    },
    setDifference(): number {
      return Math.abs(this.upgradeData.nextValue - this.upgradeData.currentValue)
    },
  },
  methods: {
    upgrade(): void {
      this.$emit('upgrade', true)
    },
  },
})
</script>

<style lang="scss" scoped>
.clubs-upgrade-popup {
  width: 67.25rem;
  height: 34.437rem;
  background-image: url($path-clubs + 'upgrade/bg-clubs-upgrade.avif');
  background-repeat: no-repeat;
  background-size: cover;

  main {
    width: 100%;
    height: 80%;
    display: flex;
    position: relative;

    .building-wrapper {
      width: 30rem;
      height: 27rem;

      h2 {
        top: 2rem;
        position: relative;
      }

      .upgrade-building {
        width: 80%;
        height: 75%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 auto;
        position: relative;

        &.current-level {
          opacity: 0.5;
        }

        &-background {
          width: 30.813rem;
          height: 19rem;
          background-image: url($path-clubs + 'upgrade/bg-clubs-upgrade-underbuilding.avif');
          background-size: contain;
          background-position: center right;
          position: absolute;
          left: -2rem;
          z-index: 0;
        }
      }

      .upgrade-building-done {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .upgrade-building-income {
        width: 17.375rem;
        height: 3.438rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        &-clubhouse {
          width: 100%;
          height: 100%;
          background-color: #0f3251;
          border: 0.125rem solid #f6c717;
          transform: $skew-value;
          line-height: 2rem;
        }
      }
    }

    .building-arrow-wrapper {
      width: 8rem;
      height: 27rem;
      position: relative;

      &-icon {
        width: 3rem;
        height: 3rem;
        position: absolute;
        top: 50%;
        left: 40%;
        transform: translate(-50%, -50%) rotate(-45deg);
        border-right: 0.35rem solid #fff;
        border-bottom: 0.35rem solid #fff;
        box-shadow: inset -1px -1px 1px #00000099;
        filter: drop-shadow(0px 0px 4px white);
      }

      &-difference {
        width: 16rem;
        height: 3.688rem;
        background: linear-gradient(
          to right,
          transparent 0%,
          #1d4979 40%,
          #1d4979 60%,
          transparent 100%
        );
        position: absolute;
        bottom: 0;
        left: -50%;

        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          left: 0;
          height: 0.063rem;
          background: linear-gradient(to right, transparent, #659edf, transparent);
        }

        &:before {
          top: 0rem;
        }

        &:after {
          bottom: 0rem;
        }
      }
    }
  }

  footer {
    height: 20%;
    width: 100%;
  }
}
</style>
