import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarToDownPath: PathObjectInterface = {
  mainPath: { x: 2491, y: 423 },
  spline: [
    2491, 423, 2584, 435, 2652, 437, 2728, 437, 2839, 439, 2923, 436, 3000, 427, 3048, 422, 3066,
    429, 3098, 481, 3124, 541, 3150, 599, 3189, 685, 3234, 777, 3275, 870, 3326, 983, 3374, 1098,
    3422, 1209, 3467, 1305, 3525, 1413, 3566, 1492, 3609, 1578, 3647, 1641, 3678, 1689, 3702, 1792,
    3731, 1888, 3760, 1974, 3796, 2068,
  ],
}
