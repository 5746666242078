import {
  CLUB_CHAMPIONSHIP,
  SET_BUBBLE_NOTIFICATIONS,
  RELOAD_BUILDINGS,
  SET_CLUB_CHAMPIONSHIP_LABEL_INFO,
  SET_BUILDINGS_TITLES_VISIBILITY,
  MANAGE_INDICATOR_VISIBILITY,
  SHOW_MAP_TUTORIAL,
  HIDE_MAP_TUTORIAL,
  SET_PREMIUM_NOTIFICATION,
  PREMIUM,
  RELOAD_TEXTS,
  SET_BUILDING_REWARDS,
  SCENES_KEYS,
  MECHANIC_MAP_ITEMS,
  CREATE_EVENT_BUILDING,
  DESTROY_EVENT_BUILDING,
  MANAGE_PROGRESS_TIMER_VISIBILITY,
  CLEAR_AND_HIDE_TIMER,
  REFRESH_PROGRESS_TIMERS,
  CLUBARENA,
  ARENA,
  EVENT_OSRA,
  EVENT_SHIP,
  MECHANIC_TOURNAMENT_BIATHLON,
  MECHANIC_TOURNAMENTS,
} from '@/map-phaser-new/constants'
import type {
  AdditionalConfigsInterface,
  BuildingCommonResponseInterface,
  BuildingConfigInterface,
  BuildingLabelInfoInterface,
  BuildingMainMapResponseInterface,
  Parameter,
  BuildingsConfigInterface,
  ExclamationNotificationConfigInterface,
  CreateBuildingsMapperInterface,
  IndicatorConfigInterface,
  TutorialConfigInterface,
  BubbleNotificationConfigInterface,
  BuildingRewardConfigInterface,
  ProgressTimerDataInterface,
} from '@/map-phaser-new/interfaces'
import type { ClubBuildingsResponse } from '@/interfaces/responses/club/ClubBuildingsResponses'
import { Building } from '@/map-phaser-new/models'
import { SpecificObjectHandler } from '@/map-phaser-new/utils/abstractClasses'
import { usePhaserGameIntegrationStore } from '@/store/pinia/map-new/phaserGameIntegrationStore'
import { useUserStore } from '@/store/pinia/userStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useClubChampionshipStore } from '@/store/pinia/clubs/championshipStore'
import router from '@/router'
import { useBuildingsStore } from '@/store/pinia/clubs/buildingStore'
import { useResponseTaskStore } from '@/store/pinia/responseTaskStore'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { useEventInfoStore } from '@/store/pinia/events/eventInfoStore'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import { EventType } from '@/interfaces/events/EventInfo'
import { translate } from '@/plugins'
import { cameraHandler } from '@/map-phaser-new/utils'
import { isWsm } from '@/plugins'

export class BuildingsHandler extends SpecificObjectHandler {
  private getBuildingsResponseBasedOnSceneMapper: CreateBuildingsMapperInterface
  private createdBuildings: Building[]
  private buildingsWithReward: Building[]

  constructor() {
    super()
    this.getBuildingsResponseBasedOnSceneMapper = {
      [SCENES_KEYS.MainMapScene]: this.getMainBuildingsResponseData.bind(this),
      [SCENES_KEYS.ClubsMapScene]: this.getClubBuildingsResponseData.bind(this),
    }
    this.createdBuildings = []
    this.buildingsWithReward = []
  }

  public async setUp(
    activeScene: Phaser.Scene,
    configData: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    await this.createBuildings(configData, activeScene, additionalConfigs)
    await this.createMainMapEventBuilding(
      configData,
      activeScene,
      additionalConfigs.exclamationNotification,
    )

    this.setCameraPosion(activeScene)
    this.initTutorial(activeScene, additionalConfigs.tutorial, configData)
  }

  private setCameraPosion(activeScene: Phaser.Scene): void {
    const buildingToCenter: String =
      activeScene.scene.key === SCENES_KEYS.MainMapScene ? ARENA : CLUBARENA
    const arenaBuilding = this.createdBuildings.find(
      (building: Building) => building.name === buildingToCenter,
    )
    if (!arenaBuilding) return

    const arenaBuildingSize = arenaBuilding.getBuildingSize()
    const positionX = arenaBuilding.positionX + arenaBuildingSize.width / 2
    const positionY = arenaBuilding.positionY + arenaBuildingSize.height / 2

    cameraHandler.setCameraPostion(activeScene, positionX, positionY)
  }

  private async createMainMapEventBuilding(
    buildingsConfig: BuildingsConfigInterface,
    activeScene: Phaser.Scene,
    exclamationNotificationConfig: ExclamationNotificationConfigInterface,
  ): Promise<void> {
    if (activeScene.scene.key !== SCENES_KEYS.MainMapScene) return

    const eventInfoStore = useEventInfoStore()
    if (
      (!eventInfoStore.getEventActive && !eventInfoStore.getEventCollectionActive) ||
      eventInfoStore.getEventLocked
    )
      return

    const phaserGameIntegrationStore = usePhaserGameIntegrationStore()
    phaserGameIntegrationStore.setActiveEventType(eventInfoStore.getEventType)

    const eventBuildingName =
      eventInfoStore.getEventType === EventType.DisciplineEvent ? EVENT_OSRA : EVENT_SHIP

    if (this.createdBuildings.find(({ name }: Building): boolean => name === eventBuildingName))
      throw new Error('Building already exists: ' + eventBuildingName)

    const getBuildingsResponseMethod =
      this.getBuildingsResponseBasedOnSceneMapper[activeScene.scene.key]
    const availableBuildingsResponseConfig = await getBuildingsResponseMethod(true)
    if (!availableBuildingsResponseConfig)
      throw new Error('Failed to fetch buildings config for scene: ' + activeScene.scene.key)

    const userStore = useUserStore()
    const showTitles = userStore.showBuildingsTitles

    const buildingConfig = buildingsConfig[eventBuildingName]
    if (!buildingConfig) throw new Error('Building config not found: ' + eventBuildingName)

    if (useUserStore().isEventShipArrived && 'tween' in buildingsConfig[eventBuildingName]) {
      delete buildingsConfig[eventBuildingName].tween
    }

    if (
      eventInfoStore.getEventType === EventType.DisciplineEvent &&
      eventInfoStore.getEventDisciplineId &&
      'frameKey' in buildingConfig
    ) {
      buildingConfig.frameKey = 'event_osra_discipline_' + eventInfoStore.getEventDisciplineId
    }

    const eventBuildingResponseConfig = availableBuildingsResponseConfig.find(
      ({ name }: BuildingCommonResponseInterface): boolean => name === eventBuildingName,
    )
    if (!eventBuildingResponseConfig) return

    const buildingInstance = this.setBuildingToMap(
      activeScene,
      buildingConfig,
      eventBuildingResponseConfig,
      showTitles,
      false,
    )

    this.createdBuildings.push(buildingInstance)

    this.setEventOsraNotification(activeScene, buildingsConfig, exclamationNotificationConfig)
  }

  private destroyMainMapEventBuilding(activeScene: Phaser.Scene): void {
    const eventInfoStore = useEventInfoStore()
    const phaserGameIntegrationStore = usePhaserGameIntegrationStore()
    phaserGameIntegrationStore.setActiveEventType(null)

    const buildingName =
      eventInfoStore.getEventType === EventType.DisciplineEvent ? EVENT_OSRA : EVENT_SHIP

    const building = this.createdBuildings.find(
      ({ name }: Building): boolean => name === buildingName,
    )
    if (!building) throw new Error('Building not found: ' + buildingName)

    building.removeBuilding()

    this.createdBuildings = this.createdBuildings.filter(
      ({ name }: Building): boolean => name !== buildingName,
    )

    this.removeEventOsraNotification(activeScene)
  }

  private async createBuildings(
    buildingsConfig: BuildingsConfigInterface,
    activeScene: Phaser.Scene,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    const getBuildingsResponseMethod =
      this.getBuildingsResponseBasedOnSceneMapper[activeScene.scene.key]
    const availableBuildingsResponseConfig = await getBuildingsResponseMethod(true)
    if (!availableBuildingsResponseConfig) return

    const userStore = useUserStore()
    const showTitles = userStore.showBuildingsTitles

    const responseTaskStore = useResponseTaskStore()
    const disciplinesStore = useDisciplineStore()

    const isSponsoredActive = responseTaskStore.hasMechanic(MECHANIC_TOURNAMENT_BIATHLON)
    const hasEarlyAccess =
      isWsm && !isSponsoredActive && !responseTaskStore.hasMechanic(MECHANIC_TOURNAMENTS)

    for (const buildingResponseConfig of availableBuildingsResponseConfig) {
      const buildingConfig = buildingsConfig[buildingResponseConfig.name]
      if (!buildingConfig) continue

      const buildingInstance = this.setBuildingToMap(
        activeScene,
        buildingConfig,
        buildingResponseConfig,
        showTitles,
        hasEarlyAccess &&
          disciplinesStore.getUnlockedDisciplinesIds.includes(
            buildingResponseConfig.sponsoredData?.disciplineId,
          ),
      )

      this.createdBuildings.push(buildingInstance)
    }
    this.registerAllEmitters(buildingsConfig, activeScene, additionalConfigs)

    if (availableBuildingsResponseConfig[0].isForeign) {
      this.resetCursorToDefault(activeScene)
      return
    }
    // additional buildings abilities - we don't have to wait for them
    this.setAdditionalAbilities(activeScene, buildingsConfig, additionalConfigs)
  }

  async createTimerTooltips(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
  ): Promise<void> {
    // after the initial loading / game refresh
    const usePhaser = usePhaserGameIntegrationStore()

    // loading & replacing the timersData object based on the response from the BE API call
    await usePhaser.loadTimers()
    const timersData = usePhaser.getTimers
    if (!timersData) return

    const timerIds = Object.keys(timersData)
    for (const id of timerIds) {
      const data = timersData[id]
      if ((data?.timerEndsAt ?? 0) <= Date.now()) {
        usePhaser.clearTimer(id)
        this.removeTimer(activeScene, id)
      } else {
        this.manageProgressTimerVisibility(activeScene, buildingsConfig, {
          buildingName: id,
          heading: translate(data.heading),
          timerStart: data.timerStartedAt,
          timerEnd: data.timerEndsAt,
        })
      }
    }
  }

  private async refreshProgressTimers(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
  ): Promise<void> {
    // force refresh already running timers
    const usePhaser = usePhaserGameIntegrationStore()

    await usePhaser.loadTimers()
    const timersData = usePhaser.getTimers
    if (!timersData) return

    // need to iterate through this process to dynamically remove existing timers from both the Phaser scene and the PhaserStore when switching tabs in mobile app/web browsers
    const timerIds = Object.keys(timersData)
    for (const id of timerIds) {
      usePhaser.clearTimer(id)
      this.removeTimer(activeScene, id)
    }

    await this.createTimerTooltips(activeScene, buildingsConfig)
  }

  private async setAdditionalAbilities(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): Promise<void> {
    return new Promise<void>((resolve: CallableFunction): void => {
      this.setCommonBuildingsAbilities(activeScene, buildingsConfig)
      this.setMainMapBuildingsAbilities(activeScene, buildingsConfig, additionalConfigs)
      this.setClubsMapBuildingsAbilities(activeScene, additionalConfigs)
      resolve()
    })
  }

  private setMainMapBuildingsAbilities(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    if (activeScene.scene.key !== SCENES_KEYS.MainMapScene) return

    this.setCareerIndicators(activeScene, buildingsConfig, additionalConfigs.indicator)
    this.setPremiumNotification(
      activeScene,
      buildingsConfig,
      additionalConfigs.exclamationNotification,
    )
    this.setBubbleNotificationForBuildings(
      activeScene,
      buildingsConfig,
      additionalConfigs.bubbleNotification,
    )
    this.createTimerTooltips(activeScene, buildingsConfig)
  }

  private setClubsMapBuildingsAbilities(
    activeScene: Phaser.Scene,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    const buildingRewardsConfig = additionalConfigs.buildingRewards
    if (activeScene.scene.key !== SCENES_KEYS.ClubsMapScene || !buildingRewardsConfig) return

    this.setBuildingsRewards(activeScene, buildingRewardsConfig)
  }

  private registerAllEmitters(
    buildingsConfig: BuildingsConfigInterface,
    activeScene: Phaser.Scene,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    const phaserGameIntergrationStore = usePhaserGameIntegrationStore()
    this.setCommonScenesBuildingsEmitters(
      phaserGameIntergrationStore.phaserEventEmitter,
      activeScene,
      buildingsConfig,
      additionalConfigs,
    )

    this.setMainMapScenesBuildingsEmitters(
      phaserGameIntergrationStore.phaserEventEmitter,
      activeScene,
      buildingsConfig,
      additionalConfigs,
    )
    this.setClubMapScenesBuildingsEmitters(
      phaserGameIntergrationStore.phaserEventEmitter,
      activeScene,
      additionalConfigs,
    )
  }

  private setBuildingsRewards(
    activeScene: Phaser.Scene,
    rewardsConfig?: BuildingRewardConfigInterface,
  ): void {
    if (!rewardsConfig) return

    const clubId = router.currentRoute.value.params.clubId
    const userClubId = useUserStore().getPlayerClubId
    const isForeign = clubId && userClubId && userClubId !== clubId

    if (isForeign) return

    if (this.buildingsWithReward?.length) {
      for (const building of this.buildingsWithReward) {
        building.removeBuildingReward()
      }
    }

    this.buildingsWithReward = []

    for (const reward of useRewardStore().getAllClubReadyForClaimRewards) {
      const buildingWithReward = this.createdBuildings.find(
        (building: Building): boolean => building.name === reward.building_type?.parameters?.name,
      )
      const rewardName = reward.rewards.gdd_id
        ? `${reward.rewards.type}-${reward.rewards.gdd_id}`
        : reward.rewards.type

      buildingWithReward.addBuildingReward(
        activeScene,
        { id: reward.club_reward_id, name: rewardName, value: reward.rewards.value },
        rewardsConfig,
      )
      this.buildingsWithReward.push(buildingWithReward)
    }
  }

  private async getMainBuildingsResponseData(
    loadData: boolean,
  ): Promise<BuildingCommonResponseInterface[]> {
    const phaserGameIntergrationStore = usePhaserGameIntegrationStore()
    if (loadData) {
      await phaserGameIntergrationStore.loadMainMapBuildings()
    }
    const buildings = phaserGameIntergrationStore.getMainMapBuildings
    const mappedData = buildings.map(
      (mainMapBuilding: BuildingMainMapResponseInterface): BuildingCommonResponseInterface => {
        const sponsoredDisciplineId = mainMapBuilding.parameters.find(
          (param: Parameter): boolean => param.name === 'discipline_id',
        )
        const sponsoredResult = mainMapBuilding.parameters.find(
          (param: Parameter): boolean => param.name === 'required_result',
        )
        return {
          name: mainMapBuilding.name,
          level: mainMapBuilding.level,
          locked: mainMapBuilding.locked,
          unlock: mainMapBuilding.unlock?.value,
          sponsoredData:
            sponsoredDisciplineId && sponsoredResult
              ? {
                  disciplineId: sponsoredDisciplineId.value,
                  requiredResult: sponsoredResult.value,
                }
              : null,
        }
      },
    )

    return mappedData
  }

  private async getClubBuildingsResponseData(
    loadData: boolean,
  ): Promise<BuildingCommonResponseInterface[] | null> {
    const clubId = router.currentRoute.value.params.clubId
    if (Array.isArray(clubId)) return null

    const buildingStore = useBuildingsStore()
    if (loadData) {
      await buildingStore.loadBuildings({
        clubId,
        refresh: true,
      })
    }
    const buildings = buildingStore.getBuildings
    const userClubId = useUserStore().getPlayerClubId
    const mappedData = buildings.map(
      (clubMapBuilding: ClubBuildingsResponse): BuildingCommonResponseInterface => {
        return {
          name: clubMapBuilding.parameters.name,
          level: clubMapBuilding.stats.level,
          locked: clubMapBuilding.stats.locked,
          unlock: clubMapBuilding.parameters.club_house_level_required?.toString(),
          isForeign: userClubId !== clubId,
        }
      },
    )

    return mappedData
  }

  private setCommonBuildingsAbilities(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
  ): void {
    this.setClubsChampionshipLabels(activeScene, buildingsConfig)
  }

  private setCareerIndicators(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    indicatorConfig?: IndicatorConfigInterface,
  ): void {
    const careerQuestIndicatorBuildings = useResponseTaskStore().getCareerQuestIndicators
    if (!careerQuestIndicatorBuildings) return

    this.manageIndicatorsVisibility(
      activeScene,
      buildingsConfig,
      careerQuestIndicatorBuildings,
      indicatorConfig,
    )
  }

  private setPremiumNotification(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    notificationConfig?: ExclamationNotificationConfigInterface,
  ): void {
    if (!notificationConfig) return

    const premiumBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === PREMIUM,
    )
    if (!premiumBuilding) return

    const config = buildingsConfig[premiumBuilding.name]
    if (!config) return

    const hasPremiumNotification = useResponseTaskStore().getPremiumNotification
    if (hasPremiumNotification) {
      premiumBuilding.addPremiumNotification(activeScene, config, notificationConfig)
      return
    }

    premiumBuilding.removePremiumNotification(activeScene)
  }

  private setEventOsraNotification(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    notificationConfig?: ExclamationNotificationConfigInterface,
  ): void {
    if (!notificationConfig) return

    const eventOsraBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === EVENT_OSRA,
    )
    if (!eventOsraBuilding) return

    const config = buildingsConfig[eventOsraBuilding.name]
    if (!config) return

    eventOsraBuilding.addEventOsraNotification(activeScene, config, notificationConfig)
  }

  private removeEventOsraNotification(activeScene: Phaser.Scene): void {
    const eventOsraBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === EVENT_OSRA,
    )
    if (!eventOsraBuilding) return

    eventOsraBuilding.removeEventOsraNotification(activeScene)
  }

  private removeClubsChampionshipLabels(): void {
    const clubChampionshipBuilding = this.createdBuildings.find(
      (building: Building) => building.name === CLUB_CHAMPIONSHIP,
    )

    if (!clubChampionshipBuilding) return

    clubChampionshipBuilding.removeLabelNew()
    clubChampionshipBuilding.removeLabelChampionshipInfo()
  }

  private setClubsChampionshipLabels(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    setInfoLabel = true,
    setNewLabel = true,
  ): void {
    const isInClubChampionship = useUserStore().isClubInChampionship
    if (!isInClubChampionship) return

    const clubChampionshipBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === CLUB_CHAMPIONSHIP,
    )
    if (!clubChampionshipBuilding) return

    const config = buildingsConfig[clubChampionshipBuilding.name]
    if (!config) return

    if (setInfoLabel) {
      this.setClubsChampionshipLabelInfo(activeScene, clubChampionshipBuilding, config)
    }

    if (setNewLabel) {
      this.setClubsChampionshipLabelNew(activeScene, clubChampionshipBuilding, config)
    }
  }

  private setClubsChampionshipLabelNew(
    activeScene: Phaser.Scene,
    clubChampionshipBuilding: Building,
    buildingConfig: BuildingConfigInterface,
  ): void {
    const wasClubChampionshipOpened = usePhaserGameIntegrationStore().wasClubChampionshipOpened
    if (wasClubChampionshipOpened) {
      clubChampionshipBuilding.removeLabelNew()
      return
    }

    clubChampionshipBuilding.addLabelNew(activeScene, buildingConfig.buildingLabelNewConfig)
  }

  private async setClubsChampionshipLabelInfo(
    activeScene: Phaser.Scene,
    clubChampionshipBuilding: Building,
    buildingConfig: BuildingConfigInterface,
    loadData = true,
  ): Promise<void> {
    const championshipStore = useClubChampionshipStore()
    if (loadData) {
      await championshipStore.loadDetail()
    }

    const championshipInfo = championshipStore.getDetail
    const dataForLabel: BuildingLabelInfoInterface = {
      badgeType: championshipInfo.division.type,
      position: championshipInfo.position.toString(),
      arrowType: championshipInfo.progress.type,
    }
    clubChampionshipBuilding.removeLabelChampionshipInfo()
    clubChampionshipBuilding.addLabelChampionshipInfo(
      activeScene,
      dataForLabel,
      buildingConfig.buildingClLabelInfoConfig,
    )
  }

  private setCommonScenesBuildingsEmitters(
    emitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    emitter.on(
      RELOAD_BUILDINGS,
      (emitData: { isForeignMap?: boolean; reloadOnly?: SCENES_KEYS }): void => {
        this.reloadBuildings(
          activeScene,
          buildingsConfig,
          emitData.reloadOnly,
          emitData.isForeignMap,
        )
      },
      this,
    )
    emitter.on(
      SET_BUILDINGS_TITLES_VISIBILITY,
      (visible: boolean): void => {
        this.setMainMapBuildingsTitlesVisibility(visible)
      },
      this,
    )
    emitter.on(SET_CLUB_CHAMPIONSHIP_LABEL_INFO, (): void => {
      this.setClubsChampionshipLabels(activeScene, buildingsConfig, true, true)
    })
    emitter.on(
      SHOW_MAP_TUTORIAL,
      (emitData: { tutorialClientId: string; isFocusItem: boolean }): void => {
        this.showTutorial(
          activeScene,
          emitData.tutorialClientId,
          additionalConfigs.tutorial,
          emitData.isFocusItem,
          buildingsConfig,
        )
      },
      this,
    )
    emitter.on(
      HIDE_MAP_TUTORIAL,
      (): void => {
        this.hideTutorial(activeScene, buildingsConfig)
      },
      this,
    )
    emitter.on(
      RELOAD_TEXTS,
      (): void => {
        this.reloadTexts(activeScene, buildingsConfig)
      },
      this,
    )
  }

  private setMainMapScenesBuildingsEmitters(
    emitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    if (activeScene.scene.key !== SCENES_KEYS.MainMapScene) return

    emitter.on(
      SET_PREMIUM_NOTIFICATION,
      (): void => {
        this.setPremiumNotification(
          activeScene,
          buildingsConfig,
          additionalConfigs.exclamationNotification,
        )
      },
      this,
    )
    emitter.on(
      MANAGE_INDICATOR_VISIBILITY,
      (questEntities: string[]): void => {
        this.manageIndicatorsVisibility(
          activeScene,
          buildingsConfig,
          questEntities,
          additionalConfigs.indicator,
        )
      },
      this,
    )
    emitter.on(
      CREATE_EVENT_BUILDING,
      (): void => {
        this.createMainMapEventBuilding(
          buildingsConfig,
          activeScene,
          additionalConfigs.exclamationNotification,
        ).catch((error: Error): void => console.error(error.toString()))
      },
      this,
    )
    emitter.on(
      DESTROY_EVENT_BUILDING,
      (): void => {
        this.destroyMainMapEventBuilding(activeScene)
      },
      this,
    )
    emitter.on(
      MANAGE_PROGRESS_TIMER_VISIBILITY,
      (timerData: ProgressTimerDataInterface): void =>
        this.manageProgressTimerVisibility(activeScene, buildingsConfig, timerData),
      this,
    )
    emitter.on(
      REFRESH_PROGRESS_TIMERS,
      (): Promise<void> => this.refreshProgressTimers(activeScene, buildingsConfig),
      this,
    )
    emitter.on(CLEAR_AND_HIDE_TIMER, (id: string) => this.removeTimer(activeScene, id), this)
    emitter.on(SET_BUBBLE_NOTIFICATIONS, (buildingName?: string): void =>
      this.setBubbleNotificationForBuildings(
        activeScene,
        buildingsConfig,
        additionalConfigs.bubbleNotification,
        buildingName,
      ),
    )
  }

  private setClubMapScenesBuildingsEmitters(
    emitter: Phaser.Events.EventEmitter,
    activeScene: Phaser.Scene,
    additionalConfigs: AdditionalConfigsInterface,
  ): void {
    if (activeScene.scene.key !== SCENES_KEYS.ClubsMapScene) return

    emitter.on(SET_BUILDING_REWARDS, (): void => {
      this.setBuildingsRewards(activeScene, additionalConfigs.buildingRewards)
    })
  }

  private manageIndicatorsVisibility(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    questEntities: string[],
    indicatorConfig?: IndicatorConfigInterface,
  ): void {
    if (!indicatorConfig) return

    // BE - helmets emitted under the tutorial_arrows array
    if (questEntities.length && questEntities[0] === MECHANIC_MAP_ITEMS) return

    if (questEntities.length) {
      this.showIndicator(activeScene, buildingsConfig, indicatorConfig, questEntities)
      return
    }

    this.hideIndicator(activeScene, questEntities)
  }

  private manageProgressTimerVisibility(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    timerData: ProgressTimerDataInterface,
  ): void {
    const id: string = timerData.buildingName

    // we proceed to rendering
    // the building itself has information and treatment about whether there is already a timer on it or not
    const focusedBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === id,
    )
    if (!focusedBuilding) return

    focusedBuilding.addProgressTimer(activeScene, buildingsConfig[id], timerData)
  }

  private removeTimer(activeScene: Phaser.Scene, id: string): void {
    const focusedBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === id,
    )

    if (!focusedBuilding) return

    const usePhaser = usePhaserGameIntegrationStore()
    usePhaser.clearTimer(id)

    focusedBuilding.removeProgressTimer(activeScene)
  }

  private setBuildingToMap(
    activeScene: Phaser.Scene,
    buildingConfig: BuildingConfigInterface,
    buildingResponseConfig: BuildingCommonResponseInterface,
    showTitles: boolean,
    hasEarlyAccess: boolean,
  ): Building {
    const buildingInstance = new Building(
      buildingResponseConfig.level,
      buildingResponseConfig.name,
      buildingResponseConfig.locked,
      buildingResponseConfig.unlock,
      buildingResponseConfig.sponsoredData,
      hasEarlyAccess,
      buildingConfig.positionX,
      buildingConfig.positionY,
    )
    buildingInstance.addToMap(activeScene, buildingConfig, buildingResponseConfig, showTitles)

    return buildingInstance
  }

  private async reloadBuildings(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    reloadOnly?: SCENES_KEYS,
    isForeignMap?: boolean,
  ): Promise<void> {
    if (reloadOnly && reloadOnly !== activeScene.scene.key) return

    const getBuildingsResponseMethod =
      this.getBuildingsResponseBasedOnSceneMapper[activeScene.scene.key]
    const availableBuildingsResponseConfig = await getBuildingsResponseMethod(true)
    this.reloadBuildingsCheck(
      activeScene,
      buildingsConfig,
      availableBuildingsResponseConfig,
      isForeignMap,
    )
    if (isForeignMap) {
      this.removeClubsChampionshipLabels()
      this.resetCursorToDefault(activeScene)
      return
    }

    this.setClubsChampionshipLabels(activeScene, buildingsConfig, false)
  }

  private reloadBuildingsCheck(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    availableBuildingsResponseConfig: BuildingCommonResponseInterface[],
    isForeignMap?: boolean,
  ): void {
    for (const existingBuilding of this.createdBuildings) {
      const newBuildingData = availableBuildingsResponseConfig.find(
        (newData: BuildingCommonResponseInterface) => newData.name === existingBuilding.name,
      )
      const buildingBaseConfig = buildingsConfig[existingBuilding.name]

      if (!buildingBaseConfig || !newBuildingData) continue

      this.checkBuildingAgainstState(
        existingBuilding,
        activeScene,
        buildingBaseConfig,
        newBuildingData,
      )
      this.checkBuildingAgainstLevel(existingBuilding, newBuildingData, buildingBaseConfig)
      this.checkBuildingAgainstInteractivity(existingBuilding, buildingBaseConfig, isForeignMap)
      if (isForeignMap) {
        existingBuilding.removeBuildingReward()
        existingBuilding.changeOutlineVisibility(false)
      }
    }
  }

  private checkBuildingAgainstInteractivity(
    existingBuilding: Building,
    buildingBaseConfig: BuildingConfigInterface,
    isForeignMap?: boolean,
  ): void {
    if (isForeignMap) {
      existingBuilding.disableInteractivity()
      return
    }

    existingBuilding.enableInteractivity(buildingBaseConfig)
  }

  private setMainMapBuildingsTitlesVisibility(visible: boolean): void {
    for (const existingBuilding of this.createdBuildings) {
      existingBuilding.setTitleVisibility(visible)
    }
  }

  private checkBuildingAgainstState(
    existingBuilding: Building,
    activeScene: Phaser.Scene,
    buildingBaseConfig: BuildingConfigInterface,
    newBuildingData: BuildingCommonResponseInterface,
  ): void {
    if (!existingBuilding.isLocked && newBuildingData.locked) {
      existingBuilding.lockBuilding(activeScene, buildingBaseConfig)
      return
    }

    if (existingBuilding.isLocked && !newBuildingData.locked) {
      existingBuilding.unlockBuilding(activeScene, buildingBaseConfig, newBuildingData.level)
    }
  }

  private checkBuildingAgainstLevel(
    existingBuilding: Building,
    newBuildingData: BuildingCommonResponseInterface,
    buildingBaseConfig: BuildingConfigInterface,
  ): void {
    if (newBuildingData.level === existingBuilding.level) {
      return
    }

    existingBuilding.changeLevel(buildingBaseConfig, newBuildingData.level)
  }

  private setBubbleNotificationForBuildings(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
    bubbleNotificationConfig?: BubbleNotificationConfigInterface,
    buildingName?: string,
  ): void {
    if (!bubbleNotificationConfig) return

    const responseStore = useResponseTaskStore()

    let createdBuildings = [...this.createdBuildings]
    if (buildingName) {
      createdBuildings = createdBuildings.filter(
        ({ name }: Building): boolean => name === buildingName,
      )
    }

    for (const existingBuilding of createdBuildings) {
      if (!bubbleNotificationConfig.buildings.includes(existingBuilding.name)) continue

      const numberOfNotifications = responseStore.calculateNotificationForBuilding(
        existingBuilding.name,
      )
      if (numberOfNotifications > 0) {
        const config = buildingsConfig[existingBuilding.name]
        if (!config) continue

        existingBuilding.setBubbleNotification(activeScene, config, bubbleNotificationConfig)
        continue
      }

      existingBuilding.removeBubbleNotification()
    }
  }

  private initTutorial(
    activeScene: Phaser.Scene,
    tutorialConfigData: TutorialConfigInterface,
    buildingsConfig: BuildingsConfigInterface,
  ): void {
    const tutorialStore = useTutorialStore()
    if (!tutorialStore.isFocusType) return

    this.showTutorial(
      activeScene,
      tutorialStore.getActualStage.clientId[0],
      tutorialConfigData,
      tutorialStore.isFocusItem,
      buildingsConfig,
    )
  }

  private showTutorial(
    activeScene: Phaser.Scene,
    tutorialClientId: string,
    tutorialConfigData: TutorialConfigInterface,
    isItemsTutorial: boolean = false,
    buildingsConfig: BuildingsConfigInterface,
  ): void {
    const otherBuildings = this.createdBuildings.filter(
      (building: Building): boolean => building.name != tutorialClientId,
    )
    if (otherBuildings.length) {
      for (const building of otherBuildings) {
        building.disableInteractivity()
        building.setTitleAlpha(0.3)
        building.setIndicatorVisibility(false)
      }
    }

    if (isItemsTutorial) return

    const focusedBuilding = this.createdBuildings.find(
      (building: Building): boolean => building.name === tutorialClientId,
    )

    if (!focusedBuilding) return

    focusedBuilding.addTutorial(activeScene, tutorialConfigData)
    focusedBuilding.setIndicatorVisibility(false)

    const tutorialSubBuilding = buildingsConfig[focusedBuilding.name].tutorialSubBuilding
    if (!tutorialSubBuilding) return

    const subFocusedBuilding = this.createdBuildings.find(
      (building: Building): boolean =>
        building.name === buildingsConfig[focusedBuilding.name].tutorialSubBuilding,
    )
    if (!subFocusedBuilding) return

    subFocusedBuilding.setDepth(tutorialConfigData)
  }

  private hideTutorial(
    activeScene: Phaser.Scene,
    buildingsBaseConfig: BuildingsConfigInterface,
  ): void {
    for (const existingBuilding of this.createdBuildings) {
      existingBuilding.removeTutorial(activeScene, buildingsBaseConfig[existingBuilding.name])
      existingBuilding.setIndicatorVisibility(true)
    }
  }

  private showIndicator(
    activeScene: Phaser.Scene,
    buildingsBaseConfig: BuildingsConfigInterface,
    indicatorConfig: IndicatorConfigInterface,
    questEntities: string[],
  ): void {
    if (useTutorialStore().getIsTutorial) return

    const questBuildingName = questEntities[0]
    const focusedBuilding = this.createdBuildings.find(
      // if the building already has an assigned indicator and its name matches focusedBuilding name, we do not add the indicator - eliminating redundancy
      // e.g. for the training hall quest
      (building: Building): boolean =>
        building.name === questBuildingName && !building.getIndicator(),
    )
    if (!focusedBuilding) return

    const buidingConfig = buildingsBaseConfig[questBuildingName]
    if (!buidingConfig?.positionIndicatorConfig) return

    focusedBuilding.addIndicator(activeScene, buidingConfig, indicatorConfig)
  }

  private hideIndicator(activeScene: Phaser.Scene, questEntities: string[]): void {
    // we are looking for building which has Indicator set, but according
    // quest this building should not have a indicator set
    const focusedBuilding = this.createdBuildings.find(
      (building: Building): boolean =>
        building.getIndicator() !== null && !questEntities.includes(building.name),
    )
    if (!focusedBuilding) return

    focusedBuilding.removeIndicator(activeScene)
  }

  private async reloadTexts(
    activeScene: Phaser.Scene,
    buildingsConfig: BuildingsConfigInterface,
  ): Promise<void> {
    const getBuildingsResponseMethod =
      this.getBuildingsResponseBasedOnSceneMapper[activeScene.scene.key]
    await getBuildingsResponseMethod(false)

    for (const building of this.createdBuildings) {
      const buildingConfig = buildingsConfig[building.name]
      if (!buildingConfig) continue

      building.reloadTexts(activeScene, buildingConfig)
    }

    // application of translation during timers
    this.refreshProgressTimers(activeScene, buildingsConfig)
  }

  private resetCursorToDefault(activeScene: Phaser.Scene): void {
    // when foreign map is shown - and user will have cursor
    // on building - cursor pointer get stuck -> user will see hand instead of
    // default cursor, so we have to set default cursor again to reset it
    const defaultCursor = activeScene.input.manager.defaultCursor
    activeScene.input.manager.setDefaultCursor(defaultCursor)
  }
}
