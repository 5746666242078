import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const skierSlalomCheckpoints: CheckpointsObjectInterface = {
  key: 'skierSlalomCheckpoints',
  data: [
    {
      x: 2471,
      y: 245,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations1',
        },
      },
    },
    {
      x: 2520,
      y: 337,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations2',
        },
      },
    },
    {
      x: 2580,
      y: 354,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations3',
        },
      },
    },
    {
      x: 2625,
      y: 402,
      width: 16,
      height: 16,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations4',
        },
      },
    },
    {
      x: 2608,
      y: 436,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations5',
        },
      },
    },
    {
      x: 2668,
      y: 479,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations3',
        },
      },
    },
    {
      x: 2656,
      y: 515,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations2',
        },
      },
    },
    {
      x: 2709,
      y: 553,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations3',
        },
      },
    },
    {
      x: 2702,
      y: 597,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations2',
        },
      },
    },
    {
      x: 2762,
      y: 628,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations6',
        },
      },
    },
    {
      x: 2738,
      y: 664,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations7',
        },
      },
    },
    {
      x: 2805,
      y: 702,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations8',
        },
      },
    },
    {
      x: 2798,
      y: 736,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations9',
        },
      },
    },
    {
      x: 2874,
      y: 755,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations8',
        },
      },
    },
    {
      x: 2877,
      y: 791,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations9',
        },
      },
    },
    {
      x: 3009,
      y: 825,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierSlalomAnimations11',
        },
      },
    },
    {
      x: 3119,
      y: 867,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.PauseAction,
        action: {
          duration: 1000,
        },
      },
    },
  ],
}
