import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'

import { pathImages } from '@/globalVariables'

export const mainMapAtlasLoadData: () => AtlasDataInterface[] = () => {
  return [
    {
      name: 'buildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildings/buildings-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildings/buildings-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildings/buildings.json?1',
        },
      },
    },
    {
      name: 'buildingsOutlines',
      multiatlas: true,
      compressed: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsOutlines/outline-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildingsOutlines/outline-astc12.json',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildingsOutlines/outline.json?2',
        },
      },
    },
    {
      name: 'lockedBuildings',
      multiatlas: true,
      compressed: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/buildings-locked-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/buildings-locked-astc12.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/buildings-locked.json?v2',
        },
      },
    },
    {
      name: 'common',
      multiatlas: true,
      compressed: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/common/common-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL: pathImages + 'map/sprites/compressed/multiatlas/common/common-astc12.json',
        },
        img: {
          multiAtlasURL: pathImages + 'map/sprites/compressed/multiatlas/common/common.json',
        },
      },
    },
    {
      name: 'animations',
      multiatlas: true,
      compressed: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/animations/map-animated-etc2rgba.json?v1',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/animations/map-animated-astc12.json?v1',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/animations/map-animated.json?v1',
        },
      },
    },
  ]
}
