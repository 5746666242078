import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarRightToUpCheckpoints: CheckpointsObjectInterface = {
  key: 'cableCarRightToUpCheckpoints',
  data: [
    {
      x: 2754,
      y: 265,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarRightToUpAnimations1',
        },
      },
    },
  ],
}
