import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const clubSkierRightCheckpoints: CheckpointsObjectInterface = {
  key: 'clubSkierRightCheckpoints',
  data: [
    {
      x: 2779,
      y: 479,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations1',
        },
      },
    },
    {
      x: 2882,
      y: 529,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations2',
        },
      },
    },
    {
      x: 3012,
      y: 597,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations3',
        },
      },
    },
    {
      x: 3112,
      y: 880,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations4',
        },
      },
    },
    {
      x: 3009,
      y: 1141,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations5',
        },
      },
    },
    {
      x: 2539,
      y: 1360,
      width: 20,
      height: 20,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'clubSkierRightAnimations6',
        },
      },
    },
  ],
}
