import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carBottomRightToLeftCheckpoints: CheckpointsObjectInterface = {
  key: 'carBottomRightToLeftCheckpoints',
  data: [
    {
      x: 1723,
      y: 1405,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations1',
        },
      },
    },
    {
      x: 1579,
      y: 1482,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations2',
        },
      },
    },
    {
      x: 1452,
      y: 1643,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations3',
        },
      },
    },
    {
      x: 1363,
      y: 1667,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations4',
        },
      },
    },
    {
      x: 1288,
      y: 1669,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations5',
        },
      },
    },
    {
      x: 1058,
      y: 1624,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomRightToLeftAnimations6',
        },
      },
    },
  ],
}
