import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const cableCarLeftToUpAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'cableCarLeftToUpAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 5,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'cableCarLeftToUpAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 3,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'cableCarLeftToUpAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '36',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 36,
        end: 34,
        zeroPad: 2,
      },
    },
  },
]
