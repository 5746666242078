import {
  BENEFITS_ARENA,
  BENEFITS_CLUB_CHAMPIONSHIP,
  BENEFITS_EVENT,
  BENEFITS_STAR_OF_THE_DAY,
  BENEFITS_TRAINING_HALL,
  BENEFIT_ATTRIBUTE_BRONZE,
  BENEFIT_ATTRIBUTE_GOLD,
  BENEFIT_ATTRIBUTE_SILVER,
  BENEFIT_CASH_BRONZE,
  BENEFIT_CASH_GOLD,
  BENEFIT_CASH_SILVER,
  BENEFIT_TRAINING_POINTS_BRONZE,
  BENEFIT_TRAINING_POINTS_GOLD,
  BENEFIT_TRAINING_POINTS_SILVER,
  ENERGY,
  ENERGY_DRINK_SHOP_ITEM_NAME,
  EVENT_CLAIMABLE_MILESTONES,
  EVENT_REPUTATION,
  GEMS,
  MECHANICS,
  MONEY,
  PROGRESS_MESSAGE_DURATION,
  STARTS,
  TRAINING_POINTS,
  UNIVERSAL_TRAINING_POINTS,
  UPDATE_PARAMETER_ENERGY,
  UPDATE_PARAMETER_GEMS,
  UPDATE_PARAMETER_MONEY,
  UPDATE_PARAMETER_STARTS,
  UPDATE_PARAMETER_UNIVERSAL_TRAINING_POINTS,
  WILD_CARD_SHOP_ITEM_NAME,
  metaInterstitialsEndpoint,
  metaNotificationsEndpoint,
  minigameTasksEndpoint,
  reportsCountEndpoint,
  reportsRewardsEndpoint,
  shopBenefitsEndpoint,
  userBenefitsSlotsEndpoint,
  userMechanicsEndpoint,
  userUpdateParameterEndpoint,
  mechanicsUnlocksEndpoint,
} from '@/globalVariables'
import { getOfferData, sendToFlutter } from '@/helpers'
import { internalAxios } from '@/plugins/vueAxios'
import moment from 'moment'
import { isMobile } from '@/plugins'
import { useConsumablesStore } from '@/store/pinia/consumablesStore'
import { usePhaserBuildingsStore } from '@/store/pinia/map/buildingsStore'
import { usePhaserGameIntegrationStore } from './map-new/phaserGameIntegrationStore'
import { usePremiumStore } from '@/store/pinia/premiumStore'
import { useTutorialStore } from '@/store/pinia/tutorialStore'
import { useUserStore } from '@/store/pinia/userStore'
import { defineStore } from 'pinia'
import { useClubStore } from './clubs/clubStore'
import { useDisciplineStore } from './disciplinesStore'
import { useMilestonesStore } from './events/milestonesStore'
import { useNewsStore } from './newsStore'
import { useMainSeasonStore } from './seasons/mainSeasonsStore'
import { useGamePassStore } from './gamePassStore'
import type {
  Notifications,
  TreeData,
  TreeDataNotification,
} from '@/interfaces/global/Notification'
import type { BuildingLevelData } from '@/interfaces/global/Level'
import type { ProgressData } from '@/interfaces/global/Progress'
import type { TaskStoreTasksApiResponse } from '@/interfaces/responses/TaskStoreApiResponse'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'
import type { ClubChampsSummary } from '@/interfaces/clubs/ClubChampionships'
import type { BenefitSlotData, UserBenefitsSlotsApiResponse } from '@/interfaces/Benefits'
import { useBuildingsStore } from './clubs/buildingStore'
import { useBoardGamesEventStore } from './boardGamesEventStore'
import {
  CAREER,
  CLUB,
  LABORATORY,
  SCENES_KEYS,
  SHOP,
  TOURNAMENTS,
  TRAINING_HALL,
} from '@/map-phaser-new/constants'
import { ApiService } from '@/services/ApiService'

const DEFAULT_NOTIFICATIONS: Notifications = {
  career_quests: [],
  daily_tasks: [],
  grand_prize_unopened: 0,
  grand_prize_unclaimed: 0,
  unread_reports: 0,
  clubs_requests: 0,
  offer: 0,
  weekly: 0,
  wild_card_addon_free: false,
  can_claim_ret_payment: false,
  video_ads_free_gems_show: true,
  energy_drink_addon_free: false,
  join_club_reminder: false,
  daily_tasks_reset: 0,
  employee_rewards_ready: 0,
  personal_trainer_count: 0,
  research_notification: {
    trees: [
      {
        tree: '',
        notifications: [
          {
            discipline_id: 0,
            cells_id: [''],
          },
        ] as TreeDataNotification[],
        count: 0,
      },
    ] as TreeData[],
    count: 0,
  },
  video_ads_free_starts_show: true,
  video_ads_free_energy_show: true,
  equipment_notifications: [],
  show_free_ad: false,
  tournaments: {
    daily_join_count: null,
    unclaimed_milestones: null,
    ibu_tab_notification: null,
  },
  events: null,
}

// TODO any
export const getArrayFromAllowedKeys = (allowedKeysArray: any, allTasks: any): any => {
  return allowedKeysArray.reduce((finalObject: any, key: any): any => {
    const finalElement = allTasks[key]
    if (finalElement) finalObject[key] = finalElement
    return finalObject
  }, {})
}

export interface NumericData {
  value: number
  max_value?: number
  free_redeem?: boolean
  parameter_name?: string
}

interface PaymentMessage {
  pack: string
  af_store_id: string
  gtm_revenue: number | string
  gtm_currency: string
  af_revenue?: number
  af_currency?: string
  af_quantity: number
  failed: boolean
  rewards: Reward[]
}

export type AllowedHeaderParams =
  | typeof ENERGY
  | typeof STARTS
  | typeof UNIVERSAL_TRAINING_POINTS
  | typeof MONEY
  | typeof GEMS

export interface TaskStoreState {
  tasks: TaskStoreTasksApiResponse
  allowedHeaderParams: AllowedHeaderParams[]
  headerParamsEndPointNames: {}
  benefits: BenefitSlotData[]
  benefitsSlots: UserBenefitsSlotsApiResponse[]
  benefitsKeys: {}
  benefitsSlotsKeys: string[]
  isParentTimer: boolean
  isFirstLoad: boolean
  disciplineBranchCompleted: number
  paymentMessage: PaymentMessage
  paymentFailed: boolean
  mobileNotificationMessages: ReportReward[]
  playInterstitial: boolean
  isPaymentLoading: boolean
}

interface Mechanic {
  mechanic_name: string
  mechanic_id: number
}

interface MechanicUnlocks {
  mechanic_name: string
  mechanic_id: number
  mechanic_unlock_type: string
  mechanic_unlock_value: number
}

export interface SessionData {
  equipmentName: string
  operationType: string
  disciplineId: Nullable<string>
  categoryId: number
  levelId: number
  taskType: string
  task: string
  target?: number
  threshold?: string
  progress?: {
    target: string
    value: string
  }
  team: string
  type?: string
  milestone?: number
  is_repeatable?: boolean
  new_milestone?: number
  piggy_bank_reward?: string
  new_piggy_bank_rarity?: string
  tournamentName?: string
  repeatable?: boolean
}

interface StatType {
  id: number
  name: string
  description: string
  created_at: string
  updated_at: string
}

interface AvailableDiscipline {
  id: number
  user_games_id: string
  games_disciplines_id: number
  discipline_tutorial_passed: number
  locked: number
  created_at: string
  updated_at: string
}

interface Stat {
  id: number
  value: number
  user_games_id: string
  created_at: string
  updated_at: string
  type: StatType
  available_discipline: AvailableDiscipline
}

interface NewStat {
  id: number
  value: number
  us_available_disciplines_id: number
  games_attributes_id: number
  created_at: string
  updated_at: string
  price: number
}

export interface UniversalTrainingPointsResponse {
  value: number
  max_value: number
  parameter_name: string
  parameter_id: number
  stat: Stat
  newStat: NewStat
}

interface TaskUpdateData {
  key: string
  taskValue: {
    value: number
    stat?: {
      value: number
      currentTp: number | null
      targetTp: number
      attribute: number
      discipline: string | null
      type: {
        name: string
      }
      available_discipline: {
        games_disciplines_id: number
      }
    }
    newStat?: {
      price: number
      value: number
    }
  }
}

export interface ResponseTasksQuestCompleted {
  discipline_id: number
  next_quest: number
  quest_number: number
  rewards: Reward[]
  unlocks: Record<string, string>
  boss_fight_progress?: string | number
}

interface RewardReportReward {
  name: string
  value: string
}

interface ReportReward {
  report_id: string
  report_type: string
  rewards: RewardReportReward[]
}

export const useResponseTaskStore = defineStore('responseTask', {
  state: (): TaskStoreState => ({
    tasks: {
      notifications: {
        ...DEFAULT_NOTIFICATIONS,
      } as Notifications,
    },
    allowedHeaderParams: [ENERGY, STARTS, UNIVERSAL_TRAINING_POINTS, MONEY, GEMS],
    headerParamsEndPointNames: {
      [ENERGY]: UPDATE_PARAMETER_ENERGY,
      [STARTS]: UPDATE_PARAMETER_STARTS,
      [UNIVERSAL_TRAINING_POINTS]: UPDATE_PARAMETER_UNIVERSAL_TRAINING_POINTS,
      [MONEY]: UPDATE_PARAMETER_MONEY,
      [GEMS]: UPDATE_PARAMETER_GEMS,
    },
    benefits: null,
    benefitsSlots: null,
    benefitsKeys: [
      BENEFIT_ATTRIBUTE_BRONZE,
      BENEFIT_ATTRIBUTE_SILVER,
      BENEFIT_ATTRIBUTE_GOLD,
      BENEFIT_CASH_BRONZE,
      BENEFIT_CASH_SILVER,
      BENEFIT_CASH_GOLD,
      BENEFIT_TRAINING_POINTS_BRONZE,
      BENEFIT_TRAINING_POINTS_SILVER,
      BENEFIT_TRAINING_POINTS_GOLD,
    ],
    benefitsSlotsKeys: [
      BENEFITS_CLUB_CHAMPIONSHIP,
      BENEFITS_ARENA,
      BENEFITS_STAR_OF_THE_DAY,
      BENEFITS_TRAINING_HALL,
      BENEFITS_EVENT,
    ],
    isParentTimer: true,
    isFirstLoad: true,
    disciplineBranchCompleted: null,
    paymentMessage: null,
    paymentFailed: false,
    mobileNotificationMessages: [],
    playInterstitial: false,
    isPaymentLoading: false,
  }),
  getters: {
    getCoolDown(): number {
      return this.tasks?.cooldown ?? 0
    },
    getTasks(): {} {
      return this.tasks
    },
    // USER PARAMS
    getMoney(): NumericData {
      return this.tasks.updateParameterMoney ?? { value: 0 }
    },
    getEnergy(): NumericData {
      return this.tasks.updateParameterEnergy ?? { value: 0, max_value: 0 }
    },
    getStarts(): NumericData {
      return this.tasks.updateParameterStarts ?? { value: 0, max_value: 0 }
    },
    getPrestige(): NumericData {
      return this.tasks.updateParameterPrestige ?? { value: 0, max_value: 0 }
    },
    getResearchPoints(): NumericData {
      return this.tasks.updateParameterResearchPoints ?? { value: 0 }
    },
    getGems(): NumericData {
      return this.tasks.updateParameterGems ?? { value: 0 }
    },
    getTrainingPoints(): NumericData {
      return this.tasks.updateParameterTrainingPoints ?? { value: 0 }
    },
    getUniversalTrainingPoints(): UniversalTrainingPointsResponse {
      return this.tasks.updateParameterUNIVERSALTRAININGPOINTS ?? { value: 0 }
    },
    getMulligans(): NumericData {
      return this.tasks.updateParameterMulligans ?? { value: 0 }
    },
    getAllowedParameters(): string[] {
      return this.allowedHeaderParams
    },
    getHeaderParams(): any {
      // TODO any
      return this.allowedHeaderParams.reduce((finalArray: any[], key: string): any[] => {
        const param = this.tasks[this.headerParamsEndPointNames[key]]
        if (param) {
          if (param?.parameter_id === 2 && this?.tasks?.event_pass_free_daily_energy) {
            param.free_redeem = true
          }
          if (param?.parameter_id === 3 && this?.tasks?.event_pass_free_daily_starts) {
            param.free_redeem = true
          }
          finalArray.push(param)
        }
        return finalArray
      }, [])
    },
    getIsParentTimer(): boolean {
      return this.isParentTimer
    },
    getFirstLoad(): boolean {
      return this.isFirstLoad
    },
    getNextRefreshStarts(): number {
      return this.tasks?.updateParameterStarts?.next_refresh
    },
    getNextRefreshEnergy(): number {
      return this.tasks?.updateParameterEnergy?.next_refresh
    },
    isUserBlocked(): boolean {
      return this.tasks?.userBlocked ?? false
    },
    // POPUPS
    getLevelUp(): BuildingLevelData {
      return this.tasks.modalLevelUp
    },
    getAchievement(): any {
      // TODO any
      return this.tasks.achievementGP
    },
    getProgress(): ProgressData {
      return this.tasks.progress
    },
    getUserLevelUp(): any {
      // TODO any
      return this.tasks.userLevelUp
    },
    getQuestCompleted(): ResponseTasksQuestCompleted {
      return this.tasks.questCompleted
    },
    getDisciplineBranchCompleted(): number {
      return this.disciplineBranchCompleted
    },
    getPaymentMessage(): PaymentMessage {
      return this.paymentMessage
    },
    getPaymentFailed(): boolean {
      return this.paymentFailed
    },
    getSessionMessage(): SessionData[] {
      return this.tasks.sessionMessages ?? []
    },
    getMobileNotificationMessages(): ReportReward[] {
      return this.mobileNotificationMessages
    },
    showClubsReminderPopup(): boolean {
      return (this.tasks.joinClubReminder ?? 0) === 1
    },
    // BENEFITS
    getBenefits(): BenefitSlotData[] {
      return this.benefits
    },
    getBenefitsSlots(): UserBenefitsSlotsApiResponse[] {
      return this.benefitsSlots
    },
    // NOTIFICATIONS
    getNotifications(): Notifications {
      return this.tasks.notifications ?? DEFAULT_NOTIFICATIONS
    },
    getPremiumNotification(): boolean {
      return (
        this.getNotifications.show_free_ad ||
        this.getNotifications.weekly > 0 ||
        this.getNotifications.video_ads_free_starts_show ||
        this.getNotifications.video_ads_free_energy_show ||
        this.getNotifications.offer > 0
      )
    },
    // MECHANICS
    getMechanics(): string[] {
      return this.tasks.unlockedMechanics
    },
    hasMechanic(): (key: string) => boolean {
      return (key: string): boolean => {
        return this.getMechanics?.includes(key) ?? false
      }
    },
    getMechanicUnlock(): (key: string) => number {
      return (key: string): number => {
        if (!this.tasks.mechanicsUnlocks) return 0
        const mechanic =
          this.tasks.mechanicsUnlocks.find(
            (mechanic: MechanicUnlocks): boolean => mechanic.mechanic_name === key,
          ) ?? null
        if (!mechanic) return 0
        return mechanic.mechanic_unlock_value ?? 0
      }
    },
    getForceRate(): any {
      // TODO any
      return this.tasks.forceRate
    },
    formatForceRate(): (price: number) => string {
      return (price: number): string => {
        return !this.tasks.forceRate || !price
          ? ''
          : ((price ?? 0) * this.tasks.forceRate.rate).toFixed(2) + this.tasks.forceRate.currency
      }
    },
    hasMechanicLocalStorage(): (key: string) => boolean {
      return (key: string): boolean => {
        return localStorage.getItem(MECHANICS)?.split(',').includes(key)
      }
    },
    // ERRORS
    getValidationErrors(): string[] {
      return this.tasks.validationErrors ?? []
    },
    // CHAMPIONSHIP SUMMARY
    getChampionshipSummary(): ClubChampsSummary {
      return this.tasks.clubChampionshipsNotifications
    },
    // END OF SEASON
    getSeasonRecalculation(): boolean {
      return this.tasks?.seasonEvaluation ?? false
    },
    showSeasonSummary(): boolean {
      return this.tasks?.showSeasonSummary ?? false
    },
    // TASK TRACKER
    getUpdateTaskTracker(): boolean {
      return this.tasks?.updateTaskTracker ?? false
    },
    // CAREER INDICATORS
    getCareerQuestIndicators(): string[] {
      return this.tasks?.tutorial_arrows ? this.tasks?.tutorial_arrows : null
    },
    isWarehouseConfigReset(): boolean {
      return this.tasks?.clearWarehouseConfig ?? false
    },
    getPlayInterstitial(): boolean {
      return this.playInterstitial
    },
  },
  actions: {
    clearCoolDown(): void {
      this.tasks.cooldown = 0
    },
    removeValidationErrors(): void {
      this.tasks.validationErrors = null
    },
    // TODO any
    updateTaskParameter(arrayToLoop: any): void {
      arrayToLoop.forEach((data: any): void => {
        // TODO any
        const task = this.headerParamsEndPointNames[data.parameter]
        if (data.increaseMaxValue && typeof this.tasks[task]?.max_value === 'number') {
          this.tasks[task].max_value += parseInt(data.value)
          return
        }
        if (typeof this.tasks[task]?.value === 'number') {
          this.tasks[task].value += parseInt(data.value)
        }
      })
    },
    // TODO any
    setParameterLimit(data: any): void {
      const task = this.headerParamsEndPointNames[data.parameter]
      if (data.limit) {
        this.tasks[task].max_value = parseInt(data.limit)
      }
    },
    updateConsumables({ key, taskValue }: TaskUpdateData): void {
      if ([ENERGY_DRINK_SHOP_ITEM_NAME, WILD_CARD_SHOP_ITEM_NAME].includes(key)) {
        const consumableStore = useConsumablesStore()
        consumableStore.setConsumableValue({
          type: key,
          value: taskValue.value,
        })
      }
    },
    updateDisciplineAttributes({ key, taskValue }: TaskUpdateData): void {
      if (
        key === UPDATE_PARAMETER_UNIVERSAL_TRAINING_POINTS &&
        taskValue?.stat &&
        taskValue?.newStat
      ) {
        const disciplineStore = useDisciplineStore()
        disciplineStore.updateDisciplineAttribute({
          currentTp:
            taskValue.stat.type?.name === TRAINING_POINTS.toUpperCase()
              ? taskValue.stat.value
              : null,
          targetTp: taskValue.newStat.price,
          attribute: taskValue.newStat.value,
          discipline: taskValue.stat.available_discipline?.games_disciplines_id,
        })
      }
    },
    playAd(): void {
      // flutter call to play interstitial ad
      // eslint-disable-next-line no-useless-escape
      sendToFlutter('{\r\n  "event": "playInterstitial",\r\n  "place": "A"\r\n}')
      // eslint-disable-next-line no-useless-escape
      sendToFlutter('{\r\n  "event": "analyticsEvent",\r\n  "eventName": "af_ad_view"\r\n }')
    },
    handleTasksNotifications(tasks: TaskUpdateData): void {
      const useBuilding = usePhaserBuildingsStore()
      const usePhaserGameIntegration = usePhaserGameIntegrationStore()

      Object.entries(tasks).forEach(
        ([key, taskValue]: [TaskUpdateData['key'], TaskUpdateData['taskValue']]): void => {
          const taskObject = { [key]: taskValue }
          if (key === 'notifications') {
            const data = {} as Notifications
            Object.entries(DEFAULT_NOTIFICATIONS).forEach(
              ([key, value]: [
                string,
                (
                  | number
                  | boolean
                  | any[]
                  | {
                      trees: TreeData[]
                      count: number
                    }
                ),
              ]): void => {
                data[key] = taskValue[key] ?? this.tasks.notifications[key] ?? value
              },
            )
            this.tasks.notifications = data
            useBuilding.reloadNotifications()
            usePhaserGameIntegration.setBubbleNotifications()
          } else {
            this.updateTask(taskObject)
          }

          // update of benefits slots from response tasks
          // e.g. after the simulation in the arena
          if (key === 'updated_benefits_slots') {
            this.benefitsSlots = taskValue as unknown as UserBenefitsSlotsApiResponse[]
          }

          if (key === 'tutorial_arrows') {
            usePhaserGameIntegrationStore().manageCareerIndicatorVisibility(taskValue)
          }

          this.updateDisciplineAttributes({ key, taskValue })
          this.updateConsumables({ key, taskValue })
        },
      )
    },
    // TODO any
    async setTasks(tasks: any): Promise<void> {
      // Clear all cached data on level-up.
      if (tasks?.levelUp || tasks?.userLevelUp || tasks?.disciplineId) {
        ApiService.$clear()
      }

      const usePremium = usePremiumStore()
      const useUser = useUserStore()
      const useClubs = useClubStore()
      const useConsumables = useConsumablesStore()
      const useMainSeason = useMainSeasonStore()
      const usePhaserGameIntegration = usePhaserGameIntegrationStore()
      const useGamePass = useGamePassStore()

      if ('reloadReportNotifications' in tasks) this.loadReportsNotifications()
      if (tasks.showPromoPopup) usePremium.showOffers(tasks.showPromoPopup)
      if (tasks.inAppReview) this.showInAppReview()
      // TODO: BE needs to implement `tasks.reloadNotifications` flag, so we don't need to check for `tasks.achievementGP` flag.
      if (tasks.reloadNotifications || tasks.achievementGP) {
        this.loadNotifications()
        // for some reason we have .notifications and .reloadNotifications from BE, need to listen on both
        usePhaserGameIntegration.setBubbleNotifications()
      }
      if (tasks.reloadRightBoxes) usePremium.loadRightboxes(true)
      if (tasks.reloadTutorial) useTutorialStore().loadTutorial()
      if (tasks.reloadMechanics) this.loadMechanics(true)
      if (tasks.updateParameterMapItems)
        usePhaserGameIntegration.updateMainMapRewardedItems(tasks.updateParameterMapItems)
      if (tasks.refreshNews) this.refreshNews()
      if (tasks.loadUserData) useUser.loadUserData()
      if (tasks.clearConsumables) useConsumables.clearConsumables()
      if (tasks.showSeasonSummary) useMainSeason.setSeasonModalState(true)
      if (tasks.updateClubParameterPrestige)
        useClubs.updateClubPrestige(tasks.updateClubParameterPrestige.value)
      if (tasks.premiumPacks) usePremium.showRefreshingOffers(tasks.premiumPacks)
      if (tasks.postTasks || (tasks.interstitial && isMobile()))
        this.processPostTasks({
          postTasks: tasks.postTasks,
          normalTasks: tasks,
        })

      const useEventMilestone = useMilestonesStore()
      if (EVENT_REPUTATION in tasks)
        useEventMilestone.setMilestonesTotalPoints(tasks[EVENT_REPUTATION])
      if (EVENT_CLAIMABLE_MILESTONES in tasks)
        useEventMilestone.setMilestonesClaimableCount(tasks[EVENT_CLAIMABLE_MILESTONES])

      this.handleTasksNotifications(tasks)
      if (tasks?.EXPERIENCE) useUser.setUserDataExperience(tasks.EXPERIENCE)
      if (tasks?.TARGET_EXPERIENCE) useUser.setUserDataTargetExperience(tasks.TARGET_EXPERIENCE)
      if (tasks?.STARTING_EXPERIENCE)
        useUser.setUserDataStartingExperience(tasks.STARTING_EXPERIENCE)

      if (tasks?.setParameterLimit) this.setParameterLimit(tasks?.setParameterLimit)

      if (tasks?.userLevelUp && useUserStore().getLevel === 1) {
        localStorage.setItem('userLevelUpToLevel2', JSON.stringify(tasks.userLevelUp))
      }
      if (tasks.reloadGamePass) {
        useGamePass.loadConfig(true)
        useGamePass.loadState(true)
      }

      if (tasks?.accountConnectedReward) {
        this.mobileNotificationMessages = tasks.accountConnectedReward
      }
    },
    // eslint-disable-next-line
    async updateParameter(parameterId: number): Promise<void> {
      await internalAxios.put<{}, boolean>(userUpdateParameterEndpoint + parameterId)
    },
    showInAppReview(): void {
      // eslint-disable-next-line no-useless-escape
      sendToFlutter('{\r\n  "event": "inAppReview"\r\n}')
    },
    async loadBenefits(): Promise<void> {
      try {
        this.benefits = await ApiService.get<BenefitSlotData[]>(shopBenefitsEndpoint, {
          force: true,
        })
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    async loadBenefitsSlots(): Promise<void> {
      try {
        this.benefitsSlots = await ApiService.get<UserBenefitsSlotsApiResponse[]>(
          userBenefitsSlotsEndpoint,
          { force: true },
        )
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    // TODO any
    updateTask(taskObject: any): void {
      // Clear all cached data on mechanic unlock.
      if (taskObject?.unlockedMechanics) {
        ApiService.$clear()
      }

      const [key, value] = Object.entries(taskObject)[0] as [string, Record<string, any>]

      if (value?.next_refresh) {
        value.finishTime = moment().add(value.next_refresh, 'seconds')
      }

      if (key === 'notifications') {
        if (value?.game_pass && 'unclaimed_milestones' in value.game_pass) {
          useGamePassStore().setNotification(value.game_pass.unclaimed_milestones)
        }
      }

      this.tasks[key] = value

      if (key === 'progress') {
        setTimeout((): void => {
          this.tasks.progress = null
        }, PROGRESS_MESSAGE_DURATION)
      }

      if (taskObject.reloadBuildings) {
        const lastShowedClubId = useBuildingsStore().getLastShowedMapClubId
        const userClubId = useUserStore().getPlayerClubId
        const reloadOnly = lastShowedClubId !== userClubId ? SCENES_KEYS.MainMapScene : undefined
        usePhaserGameIntegrationStore().reloadBuildings(reloadOnly)
      }

      if (taskObject.unlockedMechanics) {
        this.tasks.unlockedMechanics = taskObject.unlockedMechanics
        localStorage.setItem(MECHANICS, taskObject.unlockedMechanics.join(','))
      }

      if (taskObject.notifications?.employee_rewards_ready) {
        usePhaserGameIntegrationStore().setBubbleNotifications()
      }
    },
    hideLevelUp(): void {
      this.tasks.modalLevelUp = null
    },
    hideUserLevelUp(): void {
      this.tasks.userLevelUp = null
    },
    hideProgress(): void {
      this.tasks.progress = null
    },
    hideAchievementPopUp(): void {
      this.tasks.achievementGP = null
    },
    setPaymentFailed(status: boolean): void {
      this.paymentFailed = status
    },
    showPaymentLoading(): void {
      this.isPaymentLoading = true
    },
    hidePaymentLoading(): void {
      this.isPaymentLoading = false
    },
    showPaymentMessage(message: PaymentMessage): void {
      this.paymentMessage = message
    },
    hidePaymentMessage(): void {
      this.paymentMessage = null
    },
    updateParamNextRefresh({ paramName, value }: { paramName: string; value: number }): void {
      this.tasks[this.headerParamsEndPointNames[paramName]].next_refresh = value
    },
    switchParentTimer(): void {
      this.isParentTimer = !this.isParentTimer
    },
    setFirstLoad(): void {
      this.isFirstLoad = false
    },
    setUpdateTaskTracker(): void {
      this.tasks.updateTaskTracker = false
    },
    hideQuestCompleted(): void {
      this.tasks.questCompleted = null
    },
    showDisciplineBranchCompleted(disciplineId: number): void {
      this.disciplineBranchCompleted = disciplineId
    },
    hideDisciplineBranchCompleted(): void {
      this.disciplineBranchCompleted = null
    },
    async loadNotifications(): Promise<void> {
      const response = await ApiService.get<Notifications>(metaNotificationsEndpoint, {
        force: true,
      })
      this.updateTask(response)
      const useBuilding = usePhaserBuildingsStore()
      useBuilding.reloadNotifications()
    },
    async loadMechanics(force: boolean = false): Promise<void> {
      if (this?.tasks.unlockedMechanics && this?.tasks.mechanicsUnlocks && !force) return

      try {
        const mechanics = await ApiService.get<Mechanic[]>(userMechanicsEndpoint, { force })

        this.tasks.unlockedMechanics = mechanics.map(
          ({ mechanic_name }: Mechanic): string => mechanic_name,
        )
        localStorage.setItem(MECHANICS, this.tasks.unlockedMechanics.join(','))

        this.tasks.mechanicsUnlocks =
          await ApiService.get<MechanicUnlocks[]>(mechanicsUnlocksEndpoint)
      } catch (error: unknown) {
        return Promise.reject(error)
      }
    },
    addSessionMessage(sessionData: SessionData): void {
      if (!this.tasks.sessionMessages?.length) this.tasks.sessionMessages = []
      this.tasks.sessionMessages.push(sessionData)
    },
    hideSessionPopUp(): void {
      this.tasks.sessionMessages?.pop()
    },
    async loadMobileNotificationMessages(): Promise<void> {
      const response = await ApiService.get<ReportReward[]>(reportsRewardsEndpoint)
      this.mobileNotificationMessages = response
    },
    removeLastMobileNotificationMessage(): void {
      this.mobileNotificationMessages.shift()
    },
    checkInterstitial(): void {
      const useTutorial = useTutorialStore()
      if (!isMobile() || useTutorial.getIsTutorial) return
      internalAxios.get<{}, { checked: boolean }>(metaInterstitialsEndpoint)
    },
    async updateParametersAfterMinigame(): Promise<boolean> {
      await internalAxios.get<{}, boolean>(minigameTasksEndpoint)

      useBoardGamesEventStore().clearCheckTokenInterval()

      return true
    },
    async loadReportsNotifications(): Promise<void> {
      const response = await internalAxios.get<
        {},
        {
          unread_reports: number
        }
      >(reportsCountEndpoint)

      const data = {}
      // TODO any
      Object.entries(DEFAULT_NOTIFICATIONS).forEach(
        ([key, value]: [
          string,
          (
            | number
            | boolean
            | any[]
            | {
                trees: TreeData[]
                count: number
              }
          ),
        ]): void => {
          data[key] = response[key] ?? this.tasks.notifications[key] ?? value
        },
      )
      this.tasks.notifications = data
    },
    clearReportsNotifications(): void {
      const response = { unread_reports: 0 }
      const data = {}
      // TODO any
      Object.entries(DEFAULT_NOTIFICATIONS).forEach(
        ([key, value]: [
          string,
          (
            | number
            | boolean
            | any[]
            | {
                trees: TreeData[]
                count: number
              }
          ),
        ]): void => {
          data[key] = response[key] ?? this.tasks.notifications[key] ?? value
        },
      )
      this.tasks.notifications = data
    },
    // TODO any
    processPostTasks({ postTasks, normalTasks }: { postTasks: any; normalTasks: any }): void {
      if (postTasks?.length && postTasks?.length === 0) return
      const tasks = postTasks ?? normalTasks
      delete tasks.postTasks
      const useTutorial = useTutorialStore()
      const showAd = isMobile() && tasks.interstitial && !useTutorial.getIsTutorial
      const showNoAdsOffer = tasks.interstitial?.showOffer && tasks.interstitial?.pack_id
      const packId = tasks.interstitial?.pack_id
      delete tasks.interstitial
      this.playInterstitial = false
      if (showAd) {
        if (showNoAdsOffer) {
          this.playInterstitial = true
          getOfferData(packId, 'offer', internalAxios)
        } else {
          const usePremium = usePremiumStore()
          if (usePremium.getInterstitialAdLoaded) {
            this.playAd()
          }
        }
        return
      }
      this.setTasks(tasks)
    },
    hideChampionshipSumamry(): void {
      this.tasks.clubChampionshipsNotifications = null
    },
    async refreshNews(): Promise<void> {
      const newsStore = useNewsStore()
      await newsStore.loadUnreadNewsCount()
      newsStore.setReloadNews(true)
    },
    calculateNotificationForBuilding(buildingName: string): number {
      const notifications = this.getNotifications

      switch (buildingName) {
        case CAREER:
          return notifications.career_quests.length
        case CLUB:
          return notifications.clubs_requests + notifications.employee_rewards_ready
        case TRAINING_HALL:
          return notifications.personal_trainer_count
        case SHOP:
          return notifications.equipment_notifications?.length ?? 0
        case LABORATORY:
          return notifications.research_notification?.count ?? 0
        case TOURNAMENTS:
          return Object.values(notifications.tournaments).reduce(
            (acc: number, value: number | null): number => acc + (value ?? 0),
            0,
          )
        default:
          return 0
      }
    },
  },
})

export type ResponseTaskStore = ReturnType<typeof useResponseTaskStore>
