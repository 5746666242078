<template>
  <div v-if="!isContentLoading" class="arena-building-main-left relative">
    <div class="arena-building-main-left-header relative flexing">
      <div class="arena-building-main-left-header-icon">
        <div class="icon-up text-texts-standard-important">{{ userData.season.seasonMonth }}</div>
        <div class="icon-down text-texts-standard-additional">{{ userData.season.seasonYear }}</div>
      </div>
      <div class="bottom-slick" />
      <div class="arena-building-main-left-header-text">
        <p
          class="uppercase text-texts-standard-important"
          :class="{
            'text-48 font-bold': $isWsm,
            'text-36 font-semibold': $isSsm,
          }"
        >
          {{ $t('arena.seasonalBest') }}
        </p>
      </div>
    </div>
    <div class="arena-building-main-left-info w-full flex items-center">
      <div class="arena-building-main-left-info-right">
        <div class="right-text">
          <p class="uppercase font-bold text-texts-standard-default text-26 text-left">
            {{ $t('arena.highestLevel') }}
          </p>
        </div>
        <div class="rank">
          <div class="right-position text-texts-standard-important font-bold flexing">
            {{ getSeasonalArenaLevel }}
          </div>
        </div>
      </div>
      <div class="arena-building-main-left-info-left">
        <div class="trophies-text">
          <p class="uppercase font-bold text-texts-standard-default text-26 text-left">
            {{ $t('arena.rankingBest') }}
          </p>
        </div>
        <div class="trophies flexing">
          <div class="trophies-section flexing">
            <div class="left-trophies">
              <p class="text-36 text-texts-standard-important font-bold leading-8">
                {{ $filters.$formatNumber(Number(stats?.RANKING_POINTS)) ?? '-' }}
              </p>
            </div>
            <app-main-icon :icon-size="32" :icon-name="'ranking_points'" />
          </div>
        </div>
      </div>
    </div>

    <div class="arena-building-main-left-footer">
      <div
        class="arena-building-main-left-arena absolute"
        :class="$isMobile() ? 'mobile' : 'web'"
        :style="{
          background:
            'url(' +
            pathImages +
            `arena/building/BUILDING_ARENA_LARGE${$isWsm ? '_V2' : ''}_LVL` +
            1 +
            '.avif )center no-repeat',

          backgroundSize: 'contain',
        }"
      />
      <div class="arena-building-main-left-footer-header flexing">
        <p class="footer-header-width text-texts-standard-dark text-36 font-bold uppercase">
          {{ $t('arena.seasonalReward') }}
        </p>
        <app-icon
          v-tippy="{
            theme: 'WSM',
            content: $t('arena.seasonalRewardAbout'),
            placement: 'right',
            maxWidth: '30rem',
            textAlign: 'center',
          }"
          icon-name="info-50"
          class="arena-building-main-left-header-info"
        />
      </div>
      <div
        v-if="currentBuilding.level != null"
        class="footer-rewards flex items-center justify-center"
      >
        <div
          v-if="!hasSeasonalRewards"
          class="footer-text text-texts-standard-default italic text-32"
          v-html="
            $replacePlaceholder(
              $replaceHtmlPlaceholders(
                $replacePlaceholder($t('arena.seasonalRewardInfo'), '%s', '{b} %s {/b}'),
                'b',
                'text-texts-standard-important',
              ),
              '%s',
              ARENA_LEVEL,
            )
          "
        ></div>
        <div v-else class="scrollbar flex items-center">
          <reward-box
            v-for="(reward, index) in getSeasonalRewards"
            :key="index"
            class="flex justify-start"
            :reward-number="(reward as Reward).amount"
            :reward-icon-name="getRewardName(reward as Reward)"
            :reward-icon-rarity="(reward as Reward).rarity"
            :reward-icon="56"
          />
        </div>
      </div>
    </div>
  </div>

  <component-loading :is-loading="isContentLoading" width="28%" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapActions, mapState } from 'pinia'
import { ARENA, ARENA_LEVEL, ATTRIBUTE, CASH, TRAINING_POINTS, pathImages } from '@/globalVariables'
import { useMainSeasonStore } from '@/store/pinia/seasons/mainSeasonsStore'
import { useUserStore } from '@/store/pinia/userStore'

import { SeasonType } from '@/interfaces/Seasons'
import type { PropType } from 'vue'
import type { CurrentArenaBuildingResponse } from '../ArenaSharedInterface'
import type { Nullable } from '@/interfaces/utils'
import type Reward from '@/interfaces/Reward'

interface ComponentData {
  ARENA_LEVEL: typeof ARENA_LEVEL
  pathImages: typeof pathImages
}

export default defineComponent({
  name: 'ArenaBuildingSeasonInfo',
  props: {
    currentBuilding: {
      type: Object as PropType<Nullable<CurrentArenaBuildingResponse>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      ARENA_LEVEL,
      pathImages,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      stats: 'getStats',
      userData: 'getUserData',
    }),
    ...mapState(useMainSeasonStore, {
      seasonStats: 'getSeasonStats',
    }),
    isContentLoading(): boolean {
      return this.currentBuilding == null || this.stats == null
    },
    getSeasonalRewards(): Reward {
      // TODO: add rewards type if we type seasons store
      const arenaRewards = this.seasonStats.rewards.find(
        (rewards: Reward): boolean => rewards.type === ARENA,
      )

      return arenaRewards?.rewards
    },
    getSeasonalArenaLevel(): number {
      // TODO: add rewards type if we type seasons store
      const arenaRewards = this.seasonStats.rewards.find(
        (rewards: Reward): boolean => rewards.type === ARENA,
      )

      return arenaRewards?.topValue
    },
    hasSeasonalRewards(): boolean {
      return this.currentBuilding.level >= this.ARENA_LEVEL
    },
  },
  created(): void {
    this.loadSeasonStats(SeasonType.Current)
  },
  methods: {
    ...mapActions(useMainSeasonStore, {
      loadSeasonStats: 'loadSeasonStats',
    }),
    getRewardName(reward: Reward): string {
      if (reward.type === CASH || reward.type === TRAINING_POINTS || reward.type === ATTRIBUTE)
        return `${reward.name}_${reward.rarity}`

      return reward.name
    },
  },
})
</script>

<style lang="scss" scoped>
@import '@/assets/styles/views/arena/buildings.scss';
</style>
