import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const carBottomLeftToRightCheckpoints: CheckpointsObjectInterface = {
  key: 'carBottomLeftToRightCheckpoints',
  data: [
    {
      x: 1188,
      y: 1691,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations1',
        },
      },
    },
    {
      x: 1236,
      y: 1701,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations2',
        },
      },
    },
    {
      x: 1344,
      y: 1698,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations3',
        },
      },
    },
    {
      x: 1423,
      y: 1684,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations4',
        },
      },
    },
    {
      x: 1473,
      y: 1660,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations5',
        },
      },
    },
    {
      x: 1502,
      y: 1636,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations6',
        },
      },
    },
    {
      x: 1749,
      y: 1415,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'carBottomLeftToRightAnimations7',
        },
      },
    },
  ],
}
