import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarRightToDownPath: PathObjectInterface = {
  mainPath: { x: 2396, y: 184 },
  spline: [
    2436, 184, 2508, 200.5, 2652, 243.5, 2724, 265, 2743, 275, 2743, 275, 2781, 397, 2836, 549,
    2913, 757, 2966, 870, 3052, 1055, 3136, 1209, 3186, 1348, 3251, 1528, 3314, 1720, 3376, 1929,
    3419, 2100,
  ],
}
