import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarLeftToDownCheckpoints: CheckpointsObjectInterface = {
  key: 'cableCarLeftToDownCheckpoints',
  data: [
    {
      x: 499,
      y: 942,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarLeftToDownAnimations1',
        },
      },
    },
    {
      x: 1283,
      y: 1797,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarLeftToDownAnimations2',
        },
      },
    },
  ],
}
