import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const cableCarLeftToDownAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'cableCarLeftToDownAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 800,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 22,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'cableCarLeftToDownAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '22',
      delay: 0,
      duration: 700,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 22,
        end: 19,
        zeroPad: 2,
      },
    },
  },
]
