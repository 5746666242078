import type { WeatherConfigInterface } from '@/map-phaser-new/interfaces'
import { mapImageConfig } from '../../../mapImageConfig'

export const weatherConfig: WeatherConfigInterface = {
  textureKey: 'animations',
  mapFrameKey: mapImageConfig.textureKey,
  frame: ['snow_01', 'snow_02', 'snow_03', 'snow_04'],
  xCorrectionMin: 50,
  xCorrectionMax: 1.4,
  y: {
    min: -500,
    max: -10,
  },
  lifespan: {
    min: 6000,
    max: 8500,
  },
  speedX: {
    min: -800,
    max: 200,
  },

  speedY: {
    min: 150,
    max: 400,
  },
  scale: {
    start: 0.38,
    end: 0.12,
  },
  alpha: {
    start: 0.65,
    end: 0.05,
  },
  quantity: 1.2,
  blendMode: 'ADD',
  depth: 5,
}
