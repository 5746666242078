import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const cableCarToDownAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'cableCarToDownAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '28',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 28,
        end: 21,
        zeroPad: 2,
      },
    },
  },
]
