import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carBottomRightToLeftAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 7,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '07',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 7,
        end: 9,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '09',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 9,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 1300,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 4,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '04',
      delay: 0,
      duration: 1600,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 4,
        end: 2,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomRightToLeftAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '02',
      delay: 0,
      duration: 500,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 2,
        end: 1,
        zeroPad: 2,
      },
    },
  },
]
