import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const skierSlalomPath: PathObjectInterface = {
  mainPath: { x: 2395, y: 145 },
  spline: [
    2395, 145, 2491, 275, 2520, 337, 2580, 354, 2625, 402, 2608, 436, 2668, 479, 2656, 515, 2709,
    553, 2702, 597, 2762, 628, 2738, 664, 2805, 702, 2798, 736, 2874, 755, 2877, 791, 3009, 825,
    3129, 877,
  ],
}
