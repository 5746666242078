import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const carBottomLeftToRightAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '14',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 14,
        end: 15,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '15',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 15,
        end: 16,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '16',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 16,
        end: 17,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '17',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 17,
        end: 19,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations5',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '19',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 19,
        end: 20,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations6',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '20',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 20,
        end: 21,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'carBottomLeftToRightAnimations7',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '21',
      delay: 0,
      duration: 1000,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 21,
        end: 23,
        zeroPad: 2,
      },
    },
  },
]
