import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarToUpPath: PathObjectInterface = {
  mainPath: { x: 3854, y: 2008 },
  spline: [
    3849, 2008, 3803, 1885, 3763, 1785, 3734, 1710, 3702, 1629, 3664, 1564, 3611, 1470, 3575, 1405,
    3542, 1319, 3486, 1216, 3429, 1086, 3378, 985, 3326, 856, 3275, 743, 3230, 647, 3179, 551, 3141,
    462, 3117, 417, 3074, 412, 3021, 412, 2971, 419, 2894, 417, 2808, 417, 2695, 412, 2601, 407,
    2522, 403,
  ],
}
