import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const skierRightCheckpoints: CheckpointsObjectInterface = {
  key: 'skierRightCheckpoints',
  data: [
    {
      x: 2397,
      y: 877,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations1',
        },
      },
    },
    {
      x: 2455,
      y: 909,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations2',
        },
      },
    },
    {
      x: 2527,
      y: 1089,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations3',
        },
      },
    },
    {
      x: 2568,
      y: 1144,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations4',
        },
      },
    },
    {
      x: 2616,
      y: 1233,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations3',
        },
      },
    },
    {
      x: 2788,
      y: 1432,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations4',
        },
      },
    },
    {
      x: 2877,
      y: 1770,
      width: 15,
      height: 15,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'skierRightAnimations3',
        },
      },
    },
  ],
}
