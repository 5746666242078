import { CHECKPOINT_ACTION_TYPE } from '@/map-phaser-new/constants'
import type { CheckpointsObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarToDownCheckpoints: CheckpointsObjectInterface = {
  key: 'cableCarToDownCheckpoints',
  data: [
    {
      x: 3048,
      y: 422,
      width: 10,
      height: 10,
      actionData: {
        type: CHECKPOINT_ACTION_TYPE.AnimationAction,
        action: {
          normal: 'cableCarToDownAnimations1',
        },
      },
    },
  ],
}
