<template>
  <div v-if="buildingData" class="club-centrum-building-big-box bordered-1">
    <header>
      <p class="text-texts-standard-additional text-40 uppercase">
        {{ $t(buildingData.text) }}
      </p>
      <p
        class="text-texts-standard-important text-40 uppercase"
        v-html="
          $replacePlaceholder(
            $replaceHtmlPlaceholders(
              $replacePlaceholder($t('minigame.level'), '%s', '{b} %s {/b}'),
              'b',
              'text-texts-standard-important',
            ),
            '%s',
            buildingData.level,
          )
        "
      />
    </header>
    <main>
      <div class="club-centrum-building-left">
        <div
          class="club-centrum-building"
          :style="{
            backgroundImage:
              'url(' +
              pathImages +
              'clubs/buildings/building-' +
              buildingData.name.toLowerCase() +
              '-' +
              buildingData.level +
              `${$isWsm ? '_v2' : ''}.avif)`,
          }"
        />
      </div>
      <div class="club-centrum-building-right">
        <div
          v-if="buildingData.name === CLUBHOUSE && false /* tmp disabled*/"
          class="club-centrum-building-clubhouse flexing"
        >
          <app-main-icon icon-name="gp-diamond" :icon-size="152" />
          <p class="text-texts-standard-default text-36 uppercase my-2">
            {{ $t(getTaskText) }}
          </p>
          <section v-if="isComplete">
            <p class="text-texts-standard-upgrade text-36 uppercase font-bold">
              {{ $t('career.questDone') }}
            </p>
          </section>
          <section v-else class="club-centrum-building-progress">
            <app-progress-bar
              class="club-centrum-building-progress-bar"
              :goal="taskGoal"
              :actual="taskActual"
              :bar-width="15.625"
              :show-bottom-status="true"
              :counts="true"
              :show-side-counts="false"
            />
          </section>
        </div>
        <div
          v-else-if="buildingData.name === CLUBARENA"
          class="club-centrum-building-centrum flexing"
        >
          <p class="text-texts-standard-default text-36 uppercase">
            {{ $t('club.regularIncome') }}
          </p>
          <div class="club-centrum-building-income flexing">
            <clubs-periodic-payment-box
              :reward-value="buildingData.incomeData.rewardValue"
              :reward-type="buildingData.incomeData.rewardName"
              :reward-interval="buildingData.incomeData.rewardInterval"
              :reward-size="30"
              :reward-icon-size="48"
            />
          </div>
          <aside v-if="hasIncome" class="club-centrum-building-buttons">
            <app-multi-button1
              v-if="hasPicableReward"
              btn-id="club-centrum-building-button-id"
              :btn-type="MONEY"
              :btn-text="$t('common.claim')"
              :btn-count="buildingData.incomeData.rewardValue"
              @click.stop
              @click="claimIncome"
            >
            </app-multi-button1>
            <app-timer-progressbar
              v-else
              :time-max="buildingData.incomeData.rewardInterval"
              :time-remaining="remainingTime"
              :automatic="false"
            />
          </aside>
          <p
            v-else
            class="text-texts-standard-default text-30"
            v-html="
              $replacePlaceholder(
                $replaceHtmlPlaceholders(
                  $replacePlaceholder($t('club.availabilityLevel'), '{level}', '{b} {level} {/b}'),
                  'b',
                  'text-texts-standard-important',
                ),
                '{level}',
                incomeAvailabilityLevel,
              )
            "
          />
        </div>
      </div>
    </main>
  </div>
</template>

<script lang="ts">
import ClubsPeriodicPaymentBox from '@/components/Club/ClubsPeriodicPaymentBox.vue'
import AppTimerProgressbar from '@/components/GlobalComponents/AppTimerProgressbar.vue'
import { CLUBARENA, CLUBHOUSE, MONEY, pathImages } from '@/globalVariables'
import { useRewardStore } from '@/store/pinia/clubs/rewardStore'
import { mapActions, mapState } from 'pinia'
import { defineComponent } from 'vue'
import type { PropType } from 'vue'
import type { ClubBuildingData } from '@/interfaces/clubs/Clubs'
import type { Nullable } from '@/interfaces/utils'

interface ComponentData {
  CLUBHOUSE: typeof CLUBHOUSE
  CLUBARENA: typeof CLUBARENA
  MONEY: typeof MONEY
  pathImages: typeof pathImages
  taskGoal: number
  taskActual: number
  taskType: string
  rewardValue: number
  seconds: number
  incomeAvailabilityLevel: number
}

export default defineComponent({
  name: 'ClubsCentrumBuildingBigBox',
  components: {
    ClubsPeriodicPaymentBox,
    AppTimerProgressbar,
  },
  props: {
    buildingData: {
      type: Object as PropType<Nullable<ClubBuildingData>>,
      default: () => null,
    },
  },
  data(): ComponentData {
    return {
      CLUBHOUSE,
      CLUBARENA,
      MONEY,
      pathImages,
      taskGoal: 100,
      taskActual: 50,
      taskType: 'ranking_points', // ranking_points, win_competitions, training_experience,
      rewardValue: 100, // ci mam daky prijem,
      seconds: 10,
      incomeAvailabilityLevel: 2, // konstanta zo specky
    }
  },
  computed: {
    ...mapState(useRewardStore, {
      reward: 'getAllClubRewardsBySpecificId',
    }),
    remainingTime(): number {
      const buildingReward = this.reward(this.buildingData.buildingTypeId)
      if (!buildingReward || buildingReward.next_claim < 0) return 0
      return buildingReward.next_claim
    },
    hasPicableReward(): boolean {
      return this.remainingTime <= 0
    },

    isComplete(): boolean {
      return this.taskGoal === this.taskActual
    },

    getTaskText(): string {
      if (this.taskType === 'ranking_points') return 'rewards.ranking_points'
      if (this.taskType === 'win_competitions') return 'profile.competitionWins'
      if (this.taskType === 'training_experience') return 'club.trainingExperience'
      return ''
    },
    hasIncome(): boolean {
      return (this.buildingData.incomeData?.rewardValue ?? 0) > 0
    },
  },
  methods: {
    ...mapActions(useRewardStore, ['claimRewards']),
    claimIncome(): void {
      const reward = this.reward(this.buildingData.buildingTypeId)
      if (!reward) return
      this.claimRewards(reward.club_reward_id)
    },
  },
})
</script>

<style lang="scss" scoped>
.club-centrum-building-big-box {
  width: 56.75rem;
  height: 23.625rem;
  background-image: url($path-clubs + 'centrum/bg-big-box.avif');
  background-repeat: no-repeat;
  // 'contain' didn't effectively adapt entire width at scale
  background-size: 100% 100%;
  border: 0.063rem solid #2794c1;
  cursor: pointer;

  header {
    width: 100%;
    height: 3.75rem;
    background-image: url($path-clubs + 'centrum/bg-big-box-header.avif');
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // Offset for diacritical marks from above
    padding: 0.5rem 1.375rem 0;
  }

  main {
    height: 19.7rem;
    width: 100%;
    display: flex;

    .club-centrum-building-left {
      width: 35rem;
      height: 100%;

      .club-centrum-building {
        width: 30rem;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
    }

    .club-centrum-building-right {
      width: 30rem;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;

      .club-centrum-building-clubhouse {
        width: 100%;
        height: 100%;
        flex-direction: column;
      }

      .club-centrum-building-progress {
        height: 5rem;

        &-bar {
          width: 10rem;
        }
      }

      .club-centrum-building-income {
        width: 19.5rem;
        height: 4.063rem;
        margin: 0.5rem 0 2rem;
      }

      .club-centrum-building-buttons {
        width: 22.813rem;
        #club-centrum-building-button-id {
          width: 100%;
        }
      }

      .club-centrum-building-centrum {
        width: 100%;
        height: 100%;
        flex-direction: column;
      }
    }
  }
}
</style>
