import type { AtlasDataInterface } from '@/map-phaser-new/interfaces'
import { pathImages } from '@/globalVariables'

export const mainMapAtlasLoadData: () => AtlasDataInterface[] = () => {
  return [
    {
      name: 'buildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildings/wsm-buildings-etc2rgba_v3.json?v1',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildings/wsm-buildings-astc12_v3.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/buildings/wsm-buildings_v3.json?v1',
        },
      },
    },
    {
      name: 'buildingsOutlines',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsOutlines/wsm-outline-etc2rgba_v3.json?v2',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsOutlines/wsm-outline-astc12_v3.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsOutlines/wsm-outline_v3.json?v2',
        },
      },
    },
    {
      name: 'lockedBuildings',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/wsm-buildings-locked-etc2rgba_v3.json?v1',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/wsm-buildings-locked-astc12_v3.json?v1',
        },
        img: {
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/buildingsLocked/wsm-buildings-locked_v2.json?v1',
        },
      },
    },
    {
      name: 'animations',
      multiatlas: true,
      compressed: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/animations/wsm-map-animated-etc2rgba_v2.json?v2',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages +
            'map/sprites/compressed/multiatlas/animations/wsm-map-animated-astc12_v2.json?v2',
        },
        img: {
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/animations/wsm-map-animated_v2.json?v2',
        },
      },
    },
    {
      name: 'common',
      compressed: true,
      multiatlas: true,
      data: {
        etc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/common/wsm-common-etc2rgba.json',
        },
        astc: {
          type: 'KTX',
          multiAtlasURL:
            pathImages + 'map/sprites/compressed/multiatlas/common/wsm-common-astc12.json',
        },
        img: {
          multiAtlasURL: pathImages + 'map/sprites/compressed/multiatlas/common/wsm-common.json',
        },
      },
    },
  ]
}
