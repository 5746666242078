import { ANIMATION_VARIATIONS_FRAMES } from '@/map-phaser-new/constants'
import type { AnimationsData } from '@/map-phaser-new/interfaces'

export const skierLeftAnimations: AnimationsData[] = [
  {
    animationCreationData: {
      animationName: 'skierLeftAnimations1',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '05',
      delay: 0,
      duration: 100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 5,
        end: 6,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'skierLeftAnimations2',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '06',
      delay: 0,
      duration: 100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 6,
        end: 7,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'skierLeftAnimations3',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '11',
      delay: 0,
      duration: 100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 11,
        end: 10,
        zeroPad: 2,
      },
    },
  },
  {
    animationCreationData: {
      animationName: 'skierLeftAnimations4',
      skipMissedFrames: true,
      defaultTextureKeyPostfix: '10',
      delay: 9,
      duration: 100,
      showOnStart: true,
      hideOnComplete: false,
    },
    framesData: {
      type: ANIMATION_VARIATIONS_FRAMES.GenerateFrameNames,
      frames: {
        start: 10,
        end: 9,
        zeroPad: 2,
      },
    },
  },
]
