import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const carBottomLeftToRightPath: PathObjectInterface = {
  mainPath: { x: 912, y: 1571 },
  spline: [
    912, 1571, 972, 1612, 1075, 1662, 1123, 1677, 1188, 1691, 1236, 1701, 1296, 1703, 1344, 1698,
    1423, 1684, 1473, 1660, 1502, 1636, 1521, 1614, 1538, 1573, 1557, 1552, 1579, 1513, 1620, 1489,
    1725, 1446, 1749, 1415, 1749, 1396, 1728, 1362, 1711, 1312,
  ],
}
