import type { PathObjectInterface } from '@/map-phaser-new/interfaces'

export const cableCarLeftToDownPath: PathObjectInterface = {
  mainPath: { x: 1022, y: 457 },
  spline: [
    1042, 457, 982, 522, 920, 577, 836, 652, 764, 717, 677, 791, 622, 844, 567, 882, 518, 913, 499,
    942, 501, 976, 520, 1012, 554, 1065, 599, 1127, 654, 1206, 710, 1283, 750, 1343, 810, 1425, 861,
    1485, 938, 1564, 1014, 1631, 1072, 1674, 1118, 1710, 1187, 1749, 1238, 1768, 1283, 1797, 1310,
    1835, 1320, 1885, 1332, 1955, 1344, 2039, 1353, 2100,
  ],
}
